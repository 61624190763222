/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { identity } from '@bonitour/common-functions'
import { ConfirmDialog, useToast } from '@bonitour/components'
import { jsx } from '@emotion/core'
import { useForm } from '@bonitour/app-functions'
import { useCallback, useMemo } from 'react'
import { IntegrationSelect } from './IntegrationSelect'
import { validationSchema } from './Forms/Schemas/ConfirmIntegration.schema'
import { Integrations, IntegrationsFormMap } from 'Shared/constants/integrations'

const integrationBase = {
  integration: Integrations.VoucherUnico
}

export const IntegrationDialog = ({
  confirmationVisible = identity,
  toggleConfirmIntegrationVisible = identity,
  submit: emitSubmit = identity
}) => {
  const { add } = useToast()

  const {
    form,
    errors,
    onSubmit,
    utils: { onInputBlur, onInputChange }
  } = useForm(integrationBase, validationSchema)

  const {
    integration = Integrations.VoucherUnico
  } = form

  const onValidationSuccess = useCallback((integration) => {
    emitSubmit(integration)
      .then(() => {
        toggleConfirmIntegrationVisible(false)
      })
      .catch(() => add('Erro ao fazer integração'))
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onValidationError = useCallback(() => {
    add('Preencha corretamente o formulário')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLoginSubmit = useMemo(() => onSubmit(onValidationSuccess, onValidationError), [
    onSubmit,
    onValidationError,
    onValidationSuccess
  ])

  const FormComponent = useMemo(() => IntegrationsFormMap[integration], [integration])

  return (
    <>
      <ConfirmDialog
        title='Integrar com sistema externo'
        isVisible={confirmationVisible}
        successCallback={handleLoginSubmit}
        cancelCallback={toggleConfirmIntegrationVisible}
        buttonLabelConfirm='Ativar'
        buttonLabelCancel='Cancelar'
      >
        <IntegrationSelect errors={errors.integration} value={integration} onChange={onInputChange} onBlur={onInputBlur} />
        <FormComponent errors={errors} onInputChange={onInputChange} onInputBlur={onInputBlur} form={form} />
      </ConfirmDialog>
    </>
  )
}
