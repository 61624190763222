import { colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const closeDialogCss = css`
  display: none;
`

export const dialogCss = css` 
  max-width: 500px;
  color: ${colors.gray1};
  p {
    margin: 0.25rem 0 0.5rem;
  }
  button {
    margin: 0.75rem 0.75rem 0 0;
    &.primary * {
      color: ${colors.white};
    }
    > div {
      margin-top: 0;
    }
  }

`
