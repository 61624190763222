export function toQueryString (
  initialObj: object,
  options?: {
    useArrayKeys?: boolean
  }
): string {
  const { useArrayKeys = false } = options || {}
  const __JOINER = '&'

  const reducer =
    <T extends object>(
      obj: T,
      parentPrefix: string | null = null,
      hideKey: '' | 'hideKey' = ''
    ) =>
      (prev: string[], key: keyof T): string[] => {
        const val = obj[key]
        const encodedKey = encodeURIComponent(key as string | number)
        const showKey = !hideKey || useArrayKeys
        const prefix = parentPrefix
          ? `${parentPrefix}[${showKey ? encodedKey : ''}]`
          : encodedKey

        if (val == null || typeof val === 'function') {
          prev.push(`${prefix}=`)
          return prev
        }

        if (['number', 'boolean', 'string'].includes(typeof val)) {
          prev.push(
            `${prefix}=${encodeURIComponent(val as string | number | boolean)}`
          )
          return prev
        }

        if (val instanceof Date) {
          prev.push(`${prefix}=${val.toISOString()}`)
          return prev
        }

        if (typeof val === 'bigint') {
          prev.push(`${prefix}=${val.toString()}`)
          return prev
        }

        if (Array.isArray(val)) {
          prev.push(
            Object.keys(val)
              .reduce(reducer(val as any, prefix, 'hideKey'), [])
              .filter(Boolean)
              .join(__JOINER)
          )
          return prev
        }

        prev.push(
          Object.keys(val)
            .reduce(reducer(val as any, prefix), [])
            .filter(Boolean)
            .join(__JOINER)
        )
        return prev
      }

  const query = Object.keys(initialObj)
    .reduce(reducer(initialObj as any), [])
    .filter(Boolean)
    .join(__JOINER)

  return `${query}`
}
