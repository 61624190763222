import { validateCnpj } from '@bonitour/common-functions'
import { Integrations } from 'Shared/constants/integrations'
import { string } from 'yup'

/** @param {(keyof Integrations)[]} args */
const matchIntegration = (...args) => value => args.map(key => Integrations[key]).includes(value)

export const isValidSmsNumber = value => Boolean((value || '').match(/^\+?\d+$/)?.length)

export const validationSchema = {
  integration: string().required('Escolha uma integração'),
  login: string().when('integration', {
    is: matchIntegration('VoucherUnico', 'Twilio', 'Limber'),
    then: s => s
      .required('Preencha o campo login')
  }),
  password: string().when('integration', {
    is: matchIntegration('VoucherUnico', 'Twilio', 'Limber'),
    then: s => s
      .required('Preencha o campo senha')
  }),
  companyName: string().when('integration', {
    is: matchIntegration('VoucherUnico', 'Twilio'),
    then: s => s
      .required('Preencha o campo empresa')
  }),
  phone: string().when('integration', {
    is: matchIntegration('Twilio'),
    then: s => s
      .required('Preencha o campo telefone')
      .test('test-smsNumber', 'O telefone deve ser válido', isValidSmsNumber)
  }),
  token: string().when('integration', {
    is: matchIntegration('PortalTuris'),
    then: s => s
      .required('Preencha o campo token')
  }),
  cnpj: string().when('integration', {
    is: matchIntegration('PortalTuris'),
    then: s => s
      .test('test-validateCnpj', 'O CNPJ deve ser válido', validateCnpj)
      .required('O CNPJ é obrigatório')
  })
}
