import { LimberIntegrationForm } from 'Domains/Integrations/Modal/Forms/Limber'
import { PortalTurisIntegrationForm } from 'Domains/Integrations/Modal/Forms/PortalTuris'
import { TwilioIntegrationForm } from 'Domains/Integrations/Modal/Forms/Twilio'
import { VoucherUnicoIntegrationForm } from 'Domains/Integrations/Modal/Forms/VoucherUnico'

export const Integrations = {
  VoucherUnico: 'Voucher Unico',
  PortalTuris: 'Voucher Turis',
  Twilio: 'Twilio SMS',
  Limber: 'Limber'
}

export const IntegrationsLabels = {
  [Integrations.VoucherUnico]: 'Voucher Único',
  [Integrations.PortalTuris]: 'Portal Turis',
  [Integrations.Twilio]: 'Twilio SMS',
  [Integrations.Limber]: 'Limber'
}

export const IntegrationsOptions = Object.entries(IntegrationsLabels)
  .map(([value, label]) => ({ value, label }))

export const IntegrationsFormMap = {
  [Integrations.VoucherUnico]: VoucherUnicoIntegrationForm,
  [Integrations.PortalTuris]: PortalTurisIntegrationForm,
  [Integrations.Twilio]: TwilioIntegrationForm,
  [Integrations.Limber]: LimberIntegrationForm
}
