/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useCallback, useEffect } from 'react'
import { Button, H2, LoadingAnimation } from '@bonitour/components'
import { useForm } from '@bonitour/app-functions'
import { identity } from '@bonitour/common-functions'
import { LoginForm } from './Form/Form'
import { loginFormSchema } from './Form/Form.schema'
import { useLocation, useHistory } from 'react-router-dom'
import { useQuery } from 'Shared/hooks/useQuery'
import { loginFormContent } from './Login.styles'
import { returnLink } from 'Domains/AuthLayout/AuthLayout.style'
import { loadingOverlay } from 'assets/styles/global'
import { loginSelfRedirectPaths } from 'Shared/constants/loginSelfRedirectPaths'

const defaultFormState = {}

const preventFormDefault = event => event.preventDefault()

const GOOGLE_OAUTH_PATH = '/login/external_providers/google_oauth_redirect'
const { REACT_APP_API_HOST: ORB_API_URL } = process.env

export const useGoogleLogin = () => {
  const { redirectUrl } = useQuery()
  const { pathname } = useLocation()

  const onGoogle = useCallback(() => {
    const googleRedirect = loginSelfRedirectPaths.some(
      path => pathname?.startsWith(path)
    )
      ? pathname
      : redirectUrl
    const oauthUrl = new URL(GOOGLE_OAUTH_PATH, ORB_API_URL)
    oauthUrl.searchParams.append('auth_origin', window.location.origin)
    if (googleRedirect) {
      const extraData = JSON.stringify({ redirectUrl: googleRedirect })
      oauthUrl.searchParams.append('extra_data', extraData)
    }
    window.location = oauthUrl
  }, [pathname, redirectUrl])

  return onGoogle
}

export const LoginPage = ({
  onSuccess: emitSuccessEvent = identity,
  onValidationError: emitValidationError = identity,
  isLoading = false,
  mail,
  onSignup,
  onForgot
}) => {
  const {
    form,
    errors,
    onSubmit,
    utils: { onInputChange, onInputBlur }
  } = useForm(defaultFormState, loginFormSchema)

  const onClick = onSubmit(emitSuccessEvent, emitValidationError)

  const onGoogle = useGoogleLogin()

  useEffect(() => {
    if (mail && form.email !== mail) {
      onInputChange('email')(mail)
    }
  }, [mail, form.email, onInputChange])

  const { push } = useHistory()

  const handleSignup = useCallback(() => {
    if (onSignup) {
      onSignup()
    } else {
      push('/auth/signup')
    }
  }, [onSignup, push])

  return (
    <form onSubmit={preventFormDefault}>
      <H2>Fazer Login</H2>
      <div css={loginFormContent}>
        <LoginForm
          loginForm={form}
          loginFormErrors={errors}
          onChange={onInputChange}
          onBlur={onInputBlur}
          onSubmit={onClick}
          forceEmail={Boolean(mail)}
          onForgot={onForgot}
        />

        <Button
          type='submit'
          onClick={onClick}
          className='btn_email'
          tabIndex={1}
        >
          Entrar
        </Button>

        <div className='signup-container'>
          Novo na plataforma? <button type='button' onClick={handleSignup} css={returnLink}>Cadastre-se</button>
        </div>

        <div className="or-container">
          <div className="or-line"></div>
          <div className="or-text">Ou</div>
          <div className="or-line"></div>
        </div>

        <Button className='btn_google' onClick={onGoogle}>
          <img src={'/icons/google.svg'} alt='' />
            Entrar com o Google
        </Button>
      </div>
      {isLoading && (
        <div css={loadingOverlay}>
          <LoadingAnimation />
        </div>
      )}
    </form>
  )
}
