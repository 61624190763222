import React, {
  createContext,
  useContext
} from 'react'

export const defaultSortingHandler = _ => undefined

const TableContext = createContext({
  labels: [],
  sortableColumns: [],
  sortedOrder: '',
  sortedColumn: 0
})

const TableProvider = ({
  labels,
  children,
  columnsWidths,
  sortedColumn,
  sortedOrder = '',
  mobileBreakpoint = 900,
  sortableColumns = []
}) => {
  return (
    <TableContext.Provider
      value={{ labels, sortableColumns, sortedOrder, sortedColumn }}
    >
      {children}
    </TableContext.Provider>
  )
}

function useTable () {
  const context = useContext(TableContext)

  if (!context) {
    throw new Error('useTable and TableRow must be used within a Table')
  }

  return context
}

export { TableProvider, useTable }
