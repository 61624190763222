/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useCallback, useState } from 'react'
import { Button, Dialog } from '@bonitour/components'
import { authDialogCloseCss, authDialogCss } from './InviteSignupLogin.style'
import { Login } from 'Domains/Login/Login'
import { ForgotPassword } from 'Domains/ForgotPassword/ForgotPassword'
import { Signup } from 'Domains/Signup/Signup'

export const InviteSignupLogin = ({
  mail
}) => {
  const [loginDialogState, setLoginDialogState] = useState(null)
  const startLogin = useCallback(() => {
    setLoginDialogState('login')
  }, [])
  const startSignup = useCallback(() => {
    setLoginDialogState('signup')
  }, [])
  const onForgot = useCallback(() => {
    setLoginDialogState('forgot')
  }, [])
  const closeDialog = useCallback(() => {
    setLoginDialogState(null)
  }, [])

  return (
    <>
      <div className='buttons__container'>
        <Button onClick={startLogin} className='submit__button'>Já tenho uma conta</Button>
        <Button onClick={startSignup} className='submit__button'>Quero cadastrar</Button>
      </div>
      <Dialog isVisible={Boolean(loginDialogState)} onClose={closeDialog} customContainercss={authDialogCss} customButtonCss={[authDialogCloseCss]}>
        {loginDialogState === 'signup' && (
          <Signup mail={mail} onLogin={startLogin} />
        )}
        {loginDialogState === 'login' && (
          <Login mail={mail} onSignup={startSignup} onForgot={onForgot}/>
        )}
        {loginDialogState === 'forgot' && (
          <ForgotPassword mail={mail} onLogin={startLogin}/>
        )}
      </Dialog>
    </>
  )
}
