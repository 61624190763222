import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

export const AbsoluteRedirect = (to) =>
  function Redirect () {
    const params = useParams()
    const { replace } = useHistory()

    useEffect(() => {
      replace(to.replace(/:\w+/g, (match) => params[match.slice(1)]))
    }, [replace, params])

    return <></>
  }
