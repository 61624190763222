/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Button, H2 } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { useHistory } from 'react-router-dom'

import { SignupForm } from './Form/Form'
import { useForm } from '@bonitour/app-functions'
import { signupSchema } from './Form/Form.schema'
import { returnLink } from 'Domains/AuthLayout/AuthLayout.style'
import { useGoogleLogin } from 'Domains/Login/Page'
import { loginFormContent } from 'Domains/Login/Login.styles'
import { useCallback } from 'react'

const buttonContainer = css`
  display: flex;
  a {
    align-self: center;
  }
  button {
    margin-left: auto;
    margin-bottom: 14px;
    &:first-of-type:last-of-type {
      width: 100%;
    }
  }
`

const initialState = {}
const preventFormDefault = event => event.preventDefault()

export const Signup = ({
  onSuccess: emitSuccessEvent = identity,
  onValidationError: emitValidationError = identity,
  onLogin,
  mail
}) => {
  const {
    form,
    errors,
    onSubmit,
    utils: { onInputChange, onInputBlur }
  } = useForm(initialState, signupSchema)

  const onGoogle = useGoogleLogin()

  const { push } = useHistory()

  const handleLogin = useCallback(() => {
    if (onLogin) {
      onLogin()
    } else {
      push('/')
    }
  }, [onLogin, push])

  const onClick = onSubmit(emitSuccessEvent, emitValidationError)
  return (
    <form onSubmit={preventFormDefault}>
      <H2>
        Criar Conta
      </H2>
      <SignupForm signup={form} signupErrors={errors} onChange={onInputChange} onBlur={onInputBlur} forceEmail={mail} />
      <div css={buttonContainer}>
        <Button type='submit' onClick={onClick}>Cadastrar</Button>
      </div>

      <div css={loginFormContent}>
        <div className='signup-container'>
          Já possui uma conta? <button type='button' onClick={handleLogin} css={returnLink}>Realizar Login</button>
        </div>
        <div className="or-container">
          <div className="or-line"></div>
          <div className="or-text">Ou</div>
          <div className="or-line"></div>
        </div>

        <Button className='btn_google' onClick={onGoogle}>
          <img src={'/icons/google.svg'} alt='' />
            Continuar com o Google
        </Button>
      </div>
    </form>
  )
}
