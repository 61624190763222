/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/core'
import { flexRow } from '../../assets/styles/layout'

const styles = css`
  border: none;
  margin: 0;
  padding: 0;
`

export function RadioGroup ({
  children,
  name,
  disabled,
  onChange,
  onChangeReceivesEvent = false,
  value,
  className,
  customCss = [],
  ...others
}) {
  const childrenWithProps = React.Children.map(children, child => {
    return React.cloneElement(child, {
      name,
      disabled,
      checked: value === child.props.value,
      onChange: onChangeReceivesEvent ? onChange : event => onChange(event.target.value)
    })
  })

  return (
    <fieldset css={[styles, ...customCss]} {...others}>
      <div className={className}>{childrenWithProps}</div>
    </fieldset>
  )
}

export const HorizontalRadioGroup = props => <RadioGroup {...props} css={flexRow} />
