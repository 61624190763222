/* eslint-disable */

export const Freshworks = (freshworksId) => {  
  window.fwSettings={ 'widget_id': freshworksId }
  !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}() 
  const freshworksScript = document.createElement('script')
  freshworksScript.src = `https://widget.freshworks.com/widgets/${freshworksId}.js`
  freshworksScript.async = true
  freshworksScript.defer = true
  freshworksScript.onload = function() {
    window.FreshworksWidget('hide', 'launcher')
  }
  document.body.appendChild(freshworksScript)
}
