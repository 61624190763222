import { string, ref, bool } from 'yup'

export const signupSchema = {
  name: string().required('O nome completo é obrigatório'),
  email: string().email('E-mail digitado é inválido').required('O e-mail é obrigatório'),
  password: string()
    .required('Preencha o campo de senha')
    .min(6, 'A senha deve conter no mínimo 6 caracteres')
    .max(128, 'A senha deve conter no máximo 128 caracteres'),
  passwordConfirmation: string()
    .test('same-password', 'As senhas devem ser iguais', function (passwordConfirmation) {
      return passwordConfirmation === this.resolve(ref('password'))
    })
    .required('A confirmação de senha é obrigatória'),
  serviceTerms: bool()
    .oneOf([true], 'A confirmação é obrigatória')
    .required('A confirmação é obrigatória')
}
