/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Loading, H3 } from '@bonitour/components'
import { UserInviteForm } from './Form/Form'
import { useUserInvite } from './hooks/useUserInvite'
import { RequireLogoutDialog } from '../RequireLogout'
import { formContainer } from '../InviteCommon.style'

export const UserInvite = () => {
  const { isLoading, inviteInfo, heading, signupUser } = useUserInvite()

  const { email, company } = (inviteInfo || {})
  const preventFormDefault = event => event.preventDefault()

  return (
    <>
      <RequireLogoutDialog requiredEmail={email} isLoading={isLoading} />
      <form css={formContainer} onSubmit={preventFormDefault}>
        <H3>Olá, você recebeu um convite para fazer parte da empresa <span>{company}</span>!</H3>
        <UserInviteForm
          email={email}
          onAccept={signupUser}
          isLoading={isLoading}
        />
      </form>

      <Loading isLoading={isLoading} headings={heading} />
    </>
  )
}
