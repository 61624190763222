/* eslint-disable camelcase */
export const ContactsModel = data => {
  const { phones: contactsPhones = [], whatsapp = '', emails: contactsEmails = [], businessEmail = '', website = '' } = data

  const phones = contactsPhones.map(({ title, number }) => ({ title, number }))
  const emails = contactsEmails.map(({ title, address }) => ({ title, address }))

  return {
    phones,
    emails,
    whatsapp: whatsapp || undefined,
    main_email: businessEmail || undefined,
    website: website || undefined
  }
}
