/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { Email, Password, InputFormGroup, flexCenteredSpaceBetweenRow } from '@bonitour/components'
import { jsx } from '@emotion/core'
import { identity } from '@bonitour/common-functions'
import { returnLink } from 'Domains/AuthLayout/AuthLayout.style'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

export const LoginForm = ({
  loginForm = {},
  loginFormErrors = {},
  onChange: emitChangeEvent = identity,
  onBlur: emitBlurEvent = identity,
  forceEmail = false,
  onForgot
}) => {
  const { email = '', password = '' } = loginForm

  const { push } = useHistory()

  const handleForgot = useCallback(() => {
    if (onForgot) {
      onForgot()
    } else {
      push('/auth/forgot-password')
    }
  }, [onForgot, push])

  return (
    <>
      <InputFormGroup errorMessage={loginFormErrors.email} label='E-mail'>
        <Email
          name='email'
          value={email}
          onChange={emitChangeEvent('email')}
          onBlur={emitBlurEvent('email')}
          required
          disabled={forceEmail}
          tabIndex={1}
        />
      </InputFormGroup>

      <InputFormGroup
        errorMessage={loginFormErrors.password}
        label={(
          <div css={flexCenteredSpaceBetweenRow}>
            <span>Senha</span>
            <button type='button' onClick={handleForgot} css={returnLink}>
              Esqueceu?
            </button>
          </div>
        )}
      >
        <Password
          name='password'
          value={password}
          onChange={emitChangeEvent('password')}
          onBlur={emitBlurEvent('password')}
          required
          tabIndex={1}
        />
      </InputFormGroup>
    </>
  )
}
