/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { Input, InputFormGroup, flexRowCentered, HelpIcon } from '@bonitour/components'
import { jsx } from '@emotion/core'
import { helpCard, inputLogin, marginBottom15, marginTop10, width100 } from '../IntegrationDialog.styles'

export const TwilioHelp = ({ customCss = [] }) => (
  <p css={[helpCard, ...customCss]}>
    <HelpIcon />{' '}
    Para verificar o SID e Token de sua conta na Twilio,{' '}
    <a href='https://www.twilio.com/login?g=%2Fconsole%3F&t=2b1c98334b25c1a785ef15b6556396290e3c704a9b57fc40687cbccd79c46a8c' rel='noreferrer noopener' target='blank'>
      clique aqui
    </a>.
  </p>
)

export const TwilioIntegrationForm = ({ errors, onInputChange, onInputBlur, form }) => {
  return (
    <>
      <InputFormGroup errorMessage={errors.phone} label='Número de telefone' css={marginTop10}>
        <Input css={width100} value={form?.phone} onChange={onInputChange('phone')} onBlur={onInputBlur('phone')} />
      </InputFormGroup>
      <InputFormGroup errorMessage={errors.companyName} label='Empresa'>
        <Input css={width100} value={form?.companyName} onChange={onInputChange('companyName')} onBlur={onInputBlur('companyName')} />
      </InputFormGroup>
      <div css={flexRowCentered}>
        <InputFormGroup errorMessage={errors.login} label={'Conta (SID)'} css={[marginBottom15, inputLogin]}>
          <Input css={width100} value={form?.login} onChange={onInputChange('login')} onBlur={onInputBlur('login')} />
        </InputFormGroup>
        <InputFormGroup css={marginBottom15} errorMessage={errors.password} label={'Token'}>
          <Input type='password' css={width100} value={form?.password} onChange={onInputChange('password')} onBlur={onInputBlur('password')} />
        </InputFormGroup>
      </div>
      <TwilioHelp customCss={[marginBottom15]} />
    </>
  )
}
