/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { Button, Card, Input, InputFormGroup, P, useToast } from '@bonitour/components'
import { jsx } from '@emotion/core'
import { useCallback, useMemo, useState } from 'react'
import { useAuthentication } from 'Shared/contexts/Authentication'
import { useUser } from 'Shared/contexts/User'
import { InviteSignupLogin } from '../InviteSignupLogin'
import { formContainer } from '../InviteCommon.style'

export const AffiliateInviteForm = ({ affiliate = {}, token = '' }) => {
  const { userEmail } = affiliate
  const { add: addToast } = useToast()
  const { signupAffiliate } = useAuthentication()

  const { authKey, user } = useUser()
  const hasUser = useMemo(() => Boolean(authKey), [authKey])

  const [isSubmitting, setIsSubmitting] = useState(false)
  const handleSubmit = useCallback(() => {
    if (!hasUser) {
      return addToast('Você precisa se cadastrar ou realizar login para continuar', 'error')
    }
    setIsSubmitting(true)
    signupAffiliate({
      email: user?.email,
      name: user?.name,
      password: hasUser && '*',
      token
    }).then(() => {
      addToast('Convite aceito com sucesso', 'success')
      setTimeout(() => {
        window.location = '/auth/profiles'
      }, 750)
    }).catch((err) => {
      setIsSubmitting(false)
      if (typeof err.data === 'string' && err.data.toLowerCase().includes('password')) {
        addToast('Sessão expirada', 'error')
        return setTimeout(() => {
          window.location.reload()
        }, 750)
      }
      if (err.data?.errors_msg?.includes('ticket_seller::personal_info::email::taken')) {
        return addToast('O e-mail informado já está cadastrado', 'error')
      }
      addToast('Houve um erro ao tentar aceitar o convite', 'error')
    })
  }, [addToast, signupAffiliate, user, hasUser, token])

  return (
    <div css={formContainer}>
      {!hasUser && (
        <P className='description___label'>Para aceitar o convite, realize login ou cadastre-se:</P>
      )}
      <Card className='inputs__container'>
        <InputFormGroup label='E-mail'>
          <Input value={userEmail} disabled />
        </InputFormGroup>
        {hasUser
          ? (
            <>
              <InputFormGroup label='Nome completo'>
                <Input value={user?.name} disabled />
              </InputFormGroup>
              <p>
              Ao aceitar o convite, um novo perfil de afiliado será adicionado à sua conta.
              </p>
              <p>
              Acessando o painel de afiliados, será possível obter código e link de indicação, acompanhar as porcentagens e valores de comissões a serem recebidos.
              </p>
              <Button onClick={handleSubmit} className='submit__button' disabled={isSubmitting} >Aceitar convite</Button>
            </>
          )
          : (
            <>
              <p>Para aceitar o convite e acessar o painel de afiliados, é necessário cadastrar ou entrar em uma conta com este email.</p>
              <p>Após o aceite do convite, você terá acesso a um perfil de afiliados para obter código e link de indicação e acompanhar comissões.</p>
              <InviteSignupLogin mail={userEmail}/>
            </>
          )}
      </Card>
    </div>
  )
}
