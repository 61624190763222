/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { InputFormGroup, Select } from '@bonitour/components'
import { jsx } from '@emotion/core'
import { marginTop } from 'assets/styles/global'
import { useMemo } from 'react'
import { Integrations, IntegrationsOptions } from 'Shared/constants/integrations'
import { useFeatureFlag } from 'Shared/contexts/Feature'

const marginTop10 = marginTop(10)

export const IntegrationSelect = ({ errors, value, onChange: emitChangeEvent, onBlur }) => {
  const [isLimberIntegrationEnabled] = useFeatureFlag('orb-limber-integration')
  const enabledIntegrationsOptions = useMemo(
    () => IntegrationsOptions.filter(
      ({ value }) => !(
        [
          !isLimberIntegrationEnabled && Integrations.Limber
        ].filter(Boolean).includes(value)
      )
    ),
    [isLimberIntegrationEnabled]
  )

  return (
    <InputFormGroup errorMessage={errors} label='Sistema externo' css={marginTop10}>
      <Select
        options={enabledIntegrationsOptions}
        value={value}
        onChange={emitChangeEvent('integration')}
        onBlur={onBlur('integration')}
      />
    </InputFormGroup>
  )
}
