import { string, boolean, object } from 'yup'

export const registerActivitySchema = (requireActivityData = false) => ({
  id: string().required('Preencha o campo de nome'),
  code: !requireActivityData
    ? string().required('Preencha o campo de código')
    : string().nullable().optional(),
  active: boolean().required('Preencha o campo de voucher'),
  activityData: requireActivityData
    ? object().shape({
      baseForm: object().required('Preencha as informações básicas'),
      aboutForm: object().required('Preencha as informações técnicas'),
      complementaryInfoForm: object().required('Preencha as informações complementares'),
      limberData: object().required('Selecione um serviço Limber')
    })
    : object().nullable().optional()
})
