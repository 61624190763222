import { AddressModel } from 'Core/Models/Address'
import { ContactsModel } from 'Core/Models/Contact'

import { head, replaceCommaToDot } from '@bonitour/common-functions'
import { deepGetOrElse } from 'deep-getter'

export function activityModel (data, companyId) {
  const { activityId = {}, addressInfo = {}, phones = [], emails = [] } = data

  const address = AddressModel(addressInfo)
  const contacts = ContactsModel({ emails, phones })
  const image = deepGetOrElse(activityId, 'files.0.file', null)

  const { name: title = '' } = activityId

  return {
    title,
    company_id: companyId,
    image,
    document_type: 'cnpj',
    enabled: true,
    contacts,
    address
  }
}

export function activityUpdateModel (data) {
  const { activityId = {}, addressInfo = {}, phones = [], emails = [] } = data

  const address = AddressModel(addressInfo)
  const contacts = ContactsModel({ emails, phones })
  const { name: title = '' } = activityId

  const basePayload = {
    title,
    contacts,
    address
  }
  const image = (head(activityId.files.filter(({ file }) => file)) || {}).file
  if (image) {
    basePayload.image = image
  }

  return basePayload
}

export const aboutModel = (activityAbout = {}) => {
  const { duration, workDuration, category, difficultyLevel, infrastructure, generalRules } = activityAbout

  return {
    service_time: duration,
    employee_time: workDuration,
    category_id: category,
    difficulty_level: difficultyLevel,
    infrastructure,
    general_rules: generalRules
  }
}

const rulesModel = (activityRules = {}) => {
  const { minAge, minHeight, maxWeight } = activityRules.restrictions

  if (minAge === '' || minHeight === '' || maxWeight === '') {
    return {
      restrictions: {
        min_age: 0,
        min_height: 0,
        max_weight: 0
      }
    }
  }

  return {
    restrictions: {
      min_age: minAge,
      min_height: minHeight && replaceCommaToDot(minHeight),
      max_weight: maxWeight && replaceCommaToDot(maxWeight)
    }
  }
}

export const complementaryInfoModel = (activityComplementaryInfo = {}, extraDescription = {}) => {
  const { description, paymentsTypes, whatToTake, whatIsIncluded, observation, isAllyVisible, sellingDeadline } = activityComplementaryInfo

  return {
    description: JSON.stringify({
      ...extraDescription,
      'pt-br': description || extraDescription['pt-br'] || ''
    }),
    observation,
    payment_types: paymentsTypes,
    what_to_take: whatToTake,
    what_is_included: whatIsIncluded,
    ally_visible: isAllyVisible,
    selling_deadline: sellingDeadline
  }
}

export function activityAboutModel (data) {
  const { activityAbout = {}, activityRules = {} } = data

  const about = aboutModel(activityAbout)
  const rules = rulesModel(activityRules)

  return {
    ...about,
    ...rules
  }
}
