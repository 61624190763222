import { css } from '@emotion/react'
import { colors } from '../../assets/styles/colors'

export const amountInfoTooltipCss = css`
  color: ${colors.gray5};;

  span {
    display: flex;
    width: 1rem;
    height: 1rem;
    border: 1px solid ${colors.gray4};
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }

  .tooltip-on-hover {
    transform: translate(2px, -10px);
  }
`
