/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import { Portal } from '../Portal/Portal'
import { Backdrop } from '../Backdrop/Backdrop'
import { CSSTransition } from 'react-transition-group'
import { flexCenteredSpaceBetweenRow } from '../../assets/styles/layout'
import { H1 } from '../Headings/Headings'
import { CloseIcon } from '../Icons/Icons'
import { headerTitle, backdrop, container, closeIcon, transition, alignTop } from './Dialog.style'

export const Dialog = ({ onClose, children, title = '', isVisible = false, className = 'dialog', customButtonCss = [], customHeadingCss = [], customContainercss = [], ...other }) => {
  const duration = 50
  return (
    <Portal>
      {isVisible ? <Backdrop css={backdrop} onClick={onClose} /> : null}

      <CSSTransition
        in={isVisible}
        timeout={duration}
        classNames={className}
        css={transition(className, duration)}
        unmountOnExit
      >
        <div css={[container, customContainercss]} {...other}>
          <div css={[flexCenteredSpaceBetweenRow, alignTop]}>
            {title
              ? (
                <H1 css={[headerTitle, ...customHeadingCss]}>{title}</H1>
              )
              : (
                <div />
              )}
            <CloseIcon
              css={[closeIcon, ...customButtonCss]}
              onClick={onClose}
            />
          </div>
          {children}
        </div>
      </CSSTransition>
    </Portal>
  )
}

Dialog.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  className: PropTypes.string
}
