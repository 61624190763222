import { authenticatedService } from '@/interfaces/authenticatedService'
import { MetaOnRequest } from '@/types/domains/Abstractions'
import { ChangeDeadlineRulePayload, ChangeStatePayload, ConfirmData, IntegrationData, IntegrationPayload, PaidTicketsReportAttributesToExport, PaidTicketsReportGetAttributesToExport, PaidTicketsReportPayload, PaidTicketsReportSearchParams, PaidTicketsReportTotalizers, TicketPrintRes } from '@/types/domains/Tickets'
import { cleanPayload } from '@/utils/clean-payload'
import { extractData } from '@/utils/data-extractor'

const ticketService = authenticatedService('/v1/tickets')
const ticketServiceV2 = authenticatedService('/v2/tickets')
const ticketIntegrationService = authenticatedService('/v1/integration_tickets')
const pendingTicketsReportService = authenticatedService('/v1/voucherless_tickets_report')
const paidTicketsReportService = authenticatedService('v1/paid_tickets_report')

ticketService.setServiceMethods({
  getByPartialCode (code: string) {
    return ticketService.get({}, code).then(extractData)
  },
  ticketPrint (ticketId: string): Promise<TicketPrintRes | any> {
    return ticketService.get({}, `${ticketId}/voucher_digital`).then(extractData)
  },
  ticketPrintVoucherTuris (ticketId: string): Promise<TicketPrintRes | any> {
    return ticketService.get({}, `${ticketId}/voucher_digital`, { responseType: 'arraybuffer' }).then(extractData)
  },
  ticketConfirm (ticketId: string, confirmData: ConfirmData) {
    return ticketService.patch(confirmData, `${ticketId}/confirm_unchargeable`)
  },
  linkPassenger (ticketId: string, passengerId: string) {
    return ticketService.patch({ passenger_id: passengerId }, `${ticketId}/set_passenger`)
  },
  unlinkPassenger (ticketId: string) {
    return ticketService.patch({}, `${ticketId}/unbind_passenger`)
  },
  getItemsExportColumn (): Promise<any> {
    return ticketService.get({}, 'pt-br/attributes_to_export').then(extractData)
  },
  updateObservation (ticketId: string, observation: string): Promise<any> {
    return ticketService.patch({ observation }, `${ticketId}/update_observation`).then(extractData)
  },
  changeState (payload: ChangeStatePayload): Promise<any> {
    return ticketService.put(payload, 'change_state').then(extractData)
  },
  updateDeadlineRule (payload: ChangeDeadlineRulePayload): Promise<any> {
    return ticketService.patch(payload, 'update_deadline_rule').then(extractData)
  }
})

ticketIntegrationService.setServiceMethods({
  setExternalId (ticketId: string, integrationData: IntegrationData): Promise<IntegrationPayload> {
    return ticketIntegrationService.patch(integrationData, `${ticketId}/set_external_id`).then(extractData)
  }
})

ticketServiceV2.setServiceMethods({
  useTickets (ticketCodes: string[]) {
    return ticketServiceV2.patch({ ticket_code: ticketCodes }, 'use_tickets')
  }
})

pendingTicketsReportService.setServiceMethods({
  async listPendingTickets ({ searchParams, pagination }: {
    searchParams: {
      initial_date: string;
      final_date: string;
    };
    pagination: {
      page: number;
      per_page: number;
    };
  }): Promise<{
    voucherless_tickets_report: {
      ticket_slot: string;
      ticket_id: string;
      reservation_code: string;
      reservation_eko_locator: string;
      service_name: string;
      pax_name: string;
      ticket_state: string;
      reservation_id: string;
    }[];
    meta: {
      current_page: number;
      total_entries: number;
      total_pages: number;
    };
  }> {
    return pendingTicketsReportService.get({
      ...cleanPayload(searchParams),
      ...pagination
    }).then(extractData)
  }
})

paidTicketsReportService.setServiceMethods({
  async listPaidTickets ({ searchParams, pagination }: {
    searchParams: PaidTicketsReportSearchParams;
    pagination: MetaOnRequest;
  }): Promise<PaidTicketsReportPayload> {
    return paidTicketsReportService.get({
      ...cleanPayload(searchParams),
      ...pagination
    }).then(extractData)
  },
  async totals ({ searchParams }: {
    searchParams: PaidTicketsReportSearchParams;
  }): Promise<PaidTicketsReportTotalizers> {
    return paidTicketsReportService.get({
      ...cleanPayload(searchParams)
    }, 'totalizers').then(extractData)
  },
  async listAttributesToExport (lang: string): Promise<PaidTicketsReportAttributesToExport> {
    return paidTicketsReportService.get({}, `${lang}/attributes_to_export`).then(extractData)
  },
  async getAttributesToExport ({
    format,
    wantedFields,
    filters
  }: PaidTicketsReportGetAttributesToExport): Promise<any> {
    return paidTicketsReportService.get({
      ...cleanPayload({
        ...filters,
        wanted_fields: wantedFields
      })
    },
    `${format}`,
    { responseType: format === 'xlsx' ? 'arraybuffer' : undefined }).then(extractData)
  }
})

export const ticketDomain = ticketService.initialize
export const ticketDomainV2 = ticketServiceV2.initialize
export const ticketIntegrationDomain = ticketIntegrationService.initialize
export const pendingTicketsReportDomain = pendingTicketsReportService.initialize
export const paidTicketsReportDomain = paidTicketsReportService.initialize
