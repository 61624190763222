import { colors } from '@bonitour/components'
import { css } from '@emotion/core'
import { marginBottom, marginTop, width } from 'assets/styles/global'

export const marginTop10 = marginTop(10)
export const width100 = width(100)
export const marginBottom15 = marginBottom(15)

export const inputsContainer = css`
  width: 100%;
  justify-content: space-around;
  margin-top: 10px;
`

export const inputLogin = css`
  margin-right: 15px;
`

export const helpCard = css`
  font-size: 13px;
  border: 1px solid ${colors.gray7};
  background-color: ${colors.gray14};
  padding: 8px;
  border-radius: 10px;
`
