import { orbCore } from 'Core/Service/Orb/Service'
import { IntegrationModel } from 'Core/Models/Integration'
import { IntegrationParser } from 'Core/Parser/Integration'
import { convertToFormData } from '@bonitour/common-functions'

const { integrationDomain, limberIntegrationDomain } = orbCore

export const IntegrationService = {
  create (integration) {
    return integrationDomain.create(IntegrationModel.single(integration))
  },
  list ({ pagination }) {
    return integrationDomain.list(...IntegrationModel.list({ pagination })).then(IntegrationParser.list)
  },
  getById (integrationId) {
    return integrationDomain.get(integrationId).then(IntegrationParser.single)
  },
  link (integrationId, integrationLink) {
    return integrationDomain.link(integrationId, IntegrationModel.link(integrationLink))
  },
  update (integrationId, integration) {
    return integrationDomain.update(integrationId, IntegrationModel.update(integration))
  },
  remove (integrationId, integrationActivityId) {
    return integrationDomain.remove(integrationId, integrationActivityId)
  },
  limber: {
    create (integration) {
      return limberIntegrationDomain.create(IntegrationModel.single(integration))
    },
    list (integrationId) {
      return limberIntegrationDomain.list(integrationId).then(IntegrationParser.limber.list)
    },
    import (integrationId, serviceData) {
      return limberIntegrationDomain.import(
        integrationId,
        convertToFormData((IntegrationModel.limber.import(serviceData)))
      )
    }
  }
}
