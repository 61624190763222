/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react'
import { Label } from '../Label/Label'
import { clickable } from '../../assets/styles/styles'
import { colors } from '../../assets/styles/colors'
import { flexHorizontallyCentered } from '../../assets/styles/layout'
import { reset } from '../../assets/styles/input'
import { identity } from '@bonitour/common-functions'

const style = css`
  outline: none;
  position: relative;
  width: 36px;
  height: 20px;
  margin-right: 8px;
  background-color: ${colors.gray5};
  transition: 0.4s;
  border-radius: var(--small-round-element-radius, 12px);

  &:checked {
    background-color: ${colors.secondary};

    &:before {
      transform: translateX(16px);
    }
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: ${colors.white};
    transition: 0.4s;
    border-radius: calc(var(--small-round-element-radius, 50%) - 2px);
  }
`

export const Toggle = forwardRef((props, ref) => <input type='checkbox' css={[style, reset, clickable]} {...props} />)

const setFirst = css`
  order: -1;
`

const toggleLabel = css`
  color: ${colors.gray3};
  font-weight: initial;
  user-select: none;
`

export const ToggleInputGroup = forwardRef(({
  id,
  children,
  disabled: isDisabled = false,
  customCss = [],
  customToggleCss = [],
  customLabelCss = [],
  onChange = identity,
  onBlur = identity,
  canEmitToggleEventOfRow = false,
  ...other
}, ref) => {
  const [isAlreadyMounted, setIsAlreadyMounted] = useState(false)
  const [isChecked, setIsChecked] = useState(false)

  const simulateToggleClick = useCallback(() => {
    if (isDisabled) return
    setIsChecked((previous) => {
      const newValue = !previous
      onChange(newValue)
      return newValue
    })
  }, [isDisabled, onChange])

  const {
    onToggleClick = identity,
    onContainerClick = identity,
    onToggleBlur,
    onContainerBlur,
    containerRef,
    toggleRef
  } = useMemo(() => ({
    onToggleClick: canEmitToggleEventOfRow ? identity : onChange,
    onContainerClick: canEmitToggleEventOfRow ? simulateToggleClick : identity,
    onToggleBlur: canEmitToggleEventOfRow ? undefined : onBlur,
    onContainerBlur: canEmitToggleEventOfRow ? onBlur : undefined,
    containerRef: canEmitToggleEventOfRow ? ref : undefined,
    toggleRef: canEmitToggleEventOfRow ? undefined : ref
  }), [canEmitToggleEventOfRow, onBlur, onChange, ref, simulateToggleClick])

  const fillInitialValue = useCallback(() => {
    if (
      (isAlreadyMounted ||
      !canEmitToggleEventOfRow ||
      !other.checked) && other.checked === isChecked
    ) return
    setIsAlreadyMounted(true)
    setIsChecked(other?.checked)
  }, [canEmitToggleEventOfRow, isAlreadyMounted, isChecked, other.checked])

  useEffect(() => {
    fillInitialValue()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [other.checked])

  return (
    <div css={[flexHorizontallyCentered, ...customCss]} onClick={onContainerClick} ref={containerRef} onBlur={onContainerBlur}>
      <Label css={[toggleLabel, isDisabled || clickable, ...customLabelCss]} htmlFor={id}>
        {children}
      </Label>
      <Toggle
        css={[setFirst, ...customToggleCss]}
        id={id}
        disabled={isDisabled}
        onChange={onToggleClick}
        onBlur={onToggleBlur}
        {...{
          ...other,
          checked: canEmitToggleEventOfRow ? isChecked : other?.checked
        }}
        ref={toggleRef}
      />
    </div>
  )
})
