/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useMemo } from 'react'
import { jsx } from '@emotion/core'
import { CircleThumbnail } from '@bonitour/components'
import { card, imageContainer, title, resetLink } from './IntegrationCard.style'
import { getFirstLetter } from 'Shared/utils/getFirstLetter'
import { IntegrationsLabels } from 'Shared/constants/integrations'
import { NavLink } from 'react-router-dom'

export const IntegrationCard = ({ integration }) => {
  const { id, integrationName } = integration
  const integrationExpandUrl = `/app/integrations/${id}`
  const integrationLabel = useMemo(
    () => IntegrationsLabels[integrationName] || integrationName,
    [integrationName]
  )
  const integrationInitialName = useMemo(
    () => getFirstLetter(integrationLabel),
    [integrationLabel]
  )

  return (
    <NavLink to={integrationExpandUrl} css={[resetLink, card]}>
      <CircleThumbnail size={100} css={imageContainer}>
        {integrationInitialName}
      </CircleThumbnail>
      <div css={title}>{integrationLabel}</div>
    </NavLink>
  )
}
