import { useState, useEffect, useCallback } from 'react'

export function useClickOutside (...references) {
  const [isVisible, setVisibility] = useState(false)

  const handleClickOutside = useCallback(event => {
    if (references.some(reference => reference.current && reference.current.contains(event.target))) {
      return
    }
    setVisibility(false)
  }, [references])

  useEffect(() => {
    if (isVisible) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside, isVisible])

  const setVisibilityOn = useCallback(() => setVisibility(true), [])
  const setVisibilityOff = useCallback(() => setVisibility(false), [])

  return [isVisible, setVisibility, setVisibilityOn, setVisibilityOff]
}
