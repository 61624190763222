import { useMemo, useState } from 'react'
import { IntegrationService } from 'Core/Service/Integration'
import { useParams } from 'react-router-dom'
import { Integrations } from 'Shared/constants/integrations'

export const useRegisterActivity = () => {
  const [loading, setLoading] = useState(false)
  const { integrationId, integrationName } = useParams()

  const serviceMethod = useMemo(
    () => integrationName === Integrations.Limber
      ? IntegrationService.limber.import
      : IntegrationService.link,
    [integrationName]
  )

  const registerActivity = async (data) => {
    setLoading(true)
    return serviceMethod(integrationId, data).finally(() => setLoading(false))
  }

  return {
    submit: registerActivity,
    loading
  }
}
