import { BREAK_POINTS } from '@bonitour/components'
import { css } from '@emotion/core'

export const affiliateInviteContainer = css`
  width: 100vw;
  margin-left: calc(200px - 50vw);
  padding: 0 1rem;
  box-sizing: border-box;

  > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 60px;
    margin: auto 0;
    max-width: 1200px;
    margin-left: 50%;
    margin-right: -50%;
    transform: translateX(-50%);
    padding: 20px 0;
    align-items: center;
    
    svg {
      max-width: 100%;
    }
  }

  @media (max-width: ${BREAK_POINTS.bigPhone}) {
    margin-left: 0;
    padding: 0;
    width: 100%;
  }

  @media (max-width: ${BREAK_POINTS.tablet}) {
    > div {
      grid-template-columns: 1fr;
      flex-direction: column;
      padding: 20px 0;
      gap: unset;
    }
  }
`
