import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractData } from '@/utils/data-extractor'
import { cleanPayload } from '@/utils/clean-payload'
import { MetaOnRequest } from '@/types/domains/Abstractions'
import { SaleSearchParams, SalePayload } from '@/types/domains/Sale'
import { toQueryString } from '@/utils/toQueryString'

const saleReportService = authenticatedService('v3/sales_report')
const saleReportExportService = authenticatedService('v1/sales_report')

type ListParams = { searchParams: SaleSearchParams; pagination: MetaOnRequest }

const listSales = ({ searchParams, pagination }: ListParams): Promise<SalePayload> => {
  return saleReportService.get(toQueryString({
    ...cleanPayload(searchParams),
    ...pagination
  })).then(extractData)
}

const csvExport = ({ searchParams, pagination }: ListParams): Promise<string> => {
  return saleReportExportService.get({
    ...cleanPayload(searchParams),
    ...pagination
  }, 'csv').then(extractData)
}

saleReportService.setServiceMethods({ listSales })
saleReportExportService.setServiceMethods({ csvExport })

export const saleReportDomain = saleReportService.initialize
export const saleReportExportDomain = saleReportExportService.initialize
