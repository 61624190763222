/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useCallback } from 'react'
import { EmptyResult, HomeIcon } from '@bonitour/components'
import { ShortcutItem } from 'Domains/Layout/Shortcuts'

export const InviteError = () => {
  const goHome = useCallback(() => { window.location = '/' }, [])

  return (
    <>
      <EmptyResult
        size={258}
        title='Convite não encontrado'
        subtitle='Convite expirado ou código inválido'
      />
      <ShortcutItem icon={HomeIcon} label='Voltar ao início' onClick={goHome} />
    </>
  )
}
