/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { Card, H3, Row, Column, Select, Input, useToast, colors, Button, InputFormGroup, HeaderPage, LoadingAnimation } from '@bonitour/components'
import { useForm } from '@bonitour/app-functions'
import { jsx, css } from '@emotion/core'
import { useMemo, useCallback, useState, useEffect, useRef } from 'react'
import { registerActivitySchema } from './RegisterActivity.schema'
import { useRegisterActivity } from './../hooks/useRegisterActivity'
import { useActivity } from 'Shared/contexts/Activity'
import { useParams, useHistory } from 'react-router-dom'
import { Integrations } from 'Shared/constants/integrations'
import { IntegrationService } from 'Core/Service/Integration'
import { loadingContainer } from 'assets/styles/global'

const { REACT_APP_ORB_URL } = process.env

const rowBlock = css`
  padding: 20px;
  border-radius: 10px;
  margin: 40px 0px 20px 0;
  background-color: ${colors.gray14};
`

const iframeStyle = css`
  margin: -20px;
  width: calc(100% + 40px);
  border: none;
  overflow: hidden;
`

const labelActivities = (activities) =>
  activities.map((activity, index) => ({
    value: activity.id || index,
    label: activity.name || activity.title
  }))

export const RegisterActivity = ({ integration = {} }) => {
  const { add: addToast } = useToast()
  const { submit } = useRegisterActivity()
  const { companyActivities = [] } = useActivity()
  const history = useHistory()
  const { integrationName, integrationId } = useParams()
  const isLimber = useMemo(() => integrationName === Integrations.Limber, [integrationName])
  const redirectToList = useCallback(() => history.push(`/app/integrations/${integrationId}`), [history, integrationId])

  const integrationBase = useMemo(
    () =>
      Object.keys(integration).length > 0 || {
        id: '',
        code: '',
        active: true
      },
    // eslint-disable-next-line
    []
  )

  const {
    form,
    errors,
    onSubmit,
    utils: { onInputBlur, onInputChange }
  } = useForm(integrationBase, registerActivitySchema(isLimber))

  useEffect(() => {
    Object.values(errors?.activityData || {}).forEach((error) => {
      addToast(error)
    })
  }, [errors, addToast])

  const onValidationError = useCallback(() => {
    addToast('Preencha corretamente o formulário')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onValidationSuccess = useCallback((data) => {
    submit(data)
      .then(() => {
        addToast(`Atividade ${isLimber ? 'importada' : 'vinculada'} com sucesso`, 'success')
        redirectToList()
      })
      .catch((e) => {
        console.error(e)
        const errorMessage = e?.data?.errors_msg?.[0]

        const errorMessages = {
          'ticket_seller::integration_service::service_id::taken': 'Serviço já vinculado',
          'experiences::limber_experience::title::taken': 'Nome do serviço já cadastrado',
          default: `Erro ao ${isLimber ? 'importar' : 'vincular'} serviço`
          // TODO map limber already imported error
        }

        addToast(errorMessages[errorMessage] ?? errorMessages.default)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = useMemo(() => onSubmit(onValidationSuccess, onValidationError), [
    onSubmit,
    onValidationError,
    onValidationSuccess
  ])

  const [activityList, setActivityList] = useState([])
  useEffect(() => {
    if (isLimber) {
      IntegrationService.limber.list(integrationId).then(setActivityList)
    } else {
      setActivityList(companyActivities)
    }
  }, [companyActivities, integrationId, isLimber])

  const activityOptions = useMemo(() => labelActivities(activityList), [activityList])

  const [iframeHeight, setIframeHeight] = useState(0)
  const [isLoadingIframe, setIsLoadingIframe] = useState(true)

  const handleActivityFormMessage = useCallback((event) => {
    if (event?.origin !== REACT_APP_ORB_URL || !event?.data?.activityForm) {
      return
    }
    if (event.data?.activityForm?.event?.type) {
      const activityEvent = event.data.activityForm.event
      switch (activityEvent.type) {
      case 'loaded':
        setIsLoadingIframe(false)
        break
      case 'update-height':
        setIframeHeight(activityEvent.value + 40)
        break
      case 'toast':
        addToast(...activityEvent.value)
        break
      }
    }
    Object.entries(event.data?.activityForm?.change || []).forEach(([key, value]) => {
      onInputChange(`activityData.${key}`)(value)
    })
  }, [addToast, onInputChange])

  useEffect(() => {
    window.addEventListener('message', handleActivityFormMessage)
    return () => {
      window.removeEventListener('message', handleActivityFormMessage)
    }
  }, [handleActivityFormMessage])

  const iframeRef = useRef()

  const handleActivityChange = useCallback((id) => {
    onInputChange('id')(id)
    if (isLimber) {
      if (activityList?.[id]) {
        if (iframeRef.current?.contentWindow) {
          iframeRef.current.contentWindow.postMessage({
            selectedActivity: activityList[id]
          }, REACT_APP_ORB_URL)
        }
        if (activityList[id].limberData) {
          onInputChange('activityData.limberData')(activityList[id].limberData)
        }
      }
    }
  }, [activityList, onInputChange, isLimber])

  useEffect(() => {
    if (
      isLimber &&
      Object.keys(form?.activityData?.baseForm || {}).length > 0 &&
      Object.keys(form?.activityData?.aboutForm || {}).length > 0 &&
      Object.keys(form?.activityData?.complementaryInfoForm || {}).length > 0
    ) {
      handleSubmit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLimber, form])

  return (
    <>
      <HeaderPage onBack={redirectToList} title={`${isLimber ? 'Importar' : 'Vincular'} serviço`} />
      <Card>
        {isLimber && isLoadingIframe
          ? (
            <LoadingAnimation css={loadingContainer} />
          )
          : (
            <>
              <H3>Nome{isLimber ? '' : ' e código'}</H3>
              <Row css={rowBlock}>
                <Column phone={12} desktop={6}>
                  <InputFormGroup errorMessage={errors.id} label='Nome do serviço'>
                    <Select
                      placeholder='Selecione um serviço'
                      options={activityOptions}
                      value={form.id}
                      error={errors.id}
                      onChange={handleActivityChange}
                    />
                  </InputFormGroup>
                </Column>
                {!isLimber && (
                  <Column phone={12} desktop={6}>
                    <InputFormGroup errorMessage={errors.code} label='Código do serviço'>
                      <Input value={form.code} onChange={onInputChange('code')} onBlur={onInputBlur('code')} />
                    </InputFormGroup>
                  </Column>
                )}
              </Row>
            </>
          )}
        {isLimber
          ? (
            <iframe
              ref={iframeRef}
              src={`${REACT_APP_ORB_URL}/integrations/${integrationName}/${integrationId}/register-with-full-form`}
              height={iframeHeight}
              css={iframeStyle}
              // scrolling is deprecated but respected by browsers and has no similar alternative
              // check https://support.moonpoint.com/network/web/html/css/iframe-obsolete-elements.php
              scrolling='no'
            />
          )
          : (
            <Button onClick={handleSubmit}>{isLimber ? 'Importar' : 'Vincular'}</Button>
          )
        }
      </Card>
    </>
  )
}
