/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { roundedEdges } from '../../assets/styles/styles'
import { reset } from '../../assets/styles/input'
import { baseStyle, errorInput, inputContainer, removeVerticalSpacing, documentSelect, width100, passwordWithToggleSuffix, percentageInputSuffixStyle, percentageInputStyle } from './Input.style'
import { Select } from '../Select/Select'
import {
  identity,
  maskTime,
  maskMoney,
  maskCnpj,
  maskCpf,
  maskPhone,
  maskZipCode,
  maskDuration,
  maskExpirationDate,
  maskCreditCard,
  treatDurationMinutes,
  maskDate,
  maskHeight,
  maskWeight
} from '@bonitour/common-functions'
import { onNumberChange } from './functions/number'
import { useMemo, useCallback, forwardRef, useState } from 'react'
import { Prefix, Suffix } from './InputAddon'
import { EyeIcon, EyeSlashIcon } from '../Icons/Icons'

/**
 * @typedef {{
 *  onInvalid?: function,
 *  error?: boolean | string | number,
 *  customCss?: import('@emotion/core').SerializedStyles[],
 * }} InputExtendedProps
 *
 * @typedef {InputExtendedProps & React.InputHTMLAttributes<HTMLInputElement>} InputProps
 *
 * @type {React.ForwardRefExoticComponent<InputProps & React.RefAttributes<HTMLInputElement>>}
 */
export const Input = forwardRef(({
  onChange: emitChangeEvent = identity,
  onInvalid: emitInvalidEvent = identity,
  onBlur: emitBlurEvent = identity,
  onClick: emitClickEvent = identity,
  onChangeWholeEvent = false,
  error: hasError = false,
  customCss = [],
  ariaLabel,
  ...other
}, ref) => {
  const { min, max, type } = other

  const handleChange = useMemo(() => {
    if (type === 'number') {
      return onNumberChange(min, max, emitChangeEvent, onChangeWholeEvent)
    }
    return event => emitChangeEvent(onChangeWholeEvent ? event : event.target.value)
    // eslint-disable-next-line
  }, [type, max, min, emitChangeEvent])

  const handleInvalid = event => {
    event.preventDefault()
    emitInvalidEvent(!event.target.validity.valid)
  }

  const handleBlur = useCallback((event) => {
    if (onChangeWholeEvent) {
      emitBlurEvent(event)
    } else {
      emitBlurEvent(event.target.value, event)
    }
  }, [emitBlurEvent, onChangeWholeEvent])

  const handleKeyDown = useCallback((event) => {
    if (other?.onKeyDown) {
      other.onKeyDown(event)
    }

    if (
      type === 'number' &&
      !event?.key.match(/([0-9,.])|tab|enter|arrow.+|backspace|delete/i) &&
      !event?.ctrlKey &&
      !event?.metaKey
    ) {
      event.preventDefault()
    }
  }, [other, type])

  const handlePaste = useCallback((event) => {
    if (other?.onPaste) {
      other.onPaste(event)
    }

    if (type === 'number') {
      const clipboardData = event.clipboardData || window.clipboardData
      if (clipboardData?.getData('Text')?.match(/[^0-9.,]/g)) {
        event.preventDefault()
      }
    }
  }, [other, type])

  const handleCompositionUpdate = useCallback((event) => {
    if (other?.onCompositionUpdate) {
      other.onCompositionUpdate(event)
    }

    if (type === 'number') {
      event.stopPropagation()
      event.preventDefault()
      event.target.setAttribute('readonly', true)
      setTimeout(() => {
        event.target.removeAttribute('readonly')
      }, 200)
    }
  }, [other, type])

  return (
    <input
      {...other}
      ref={ref}
      css={[baseStyle, roundedEdges, reset, hasError && errorInput, ...customCss]}
      onInvalid={handleInvalid}
      onChange={handleChange}
      onBlur={handleBlur}
      onClick={emitClickEvent}
      aria-label={ariaLabel}
      onKeyDown={handleKeyDown}
      onPaste={handlePaste}
      onCompositionUpdate={handleCompositionUpdate}
    />
  )
})

export function Number (props) {
  const { min, max, onChange: emitChangeEvent } = props
  const onChange = onNumberChange(min, max, emitChangeEvent)
  return <Input onChange={onChange} {...props} type='password' />
}

export function Password (props) {
  return <Input {...props} type='password' />
}

export function PasswordWithToggle (props) {
  const [isHidded, setIsHideed] = useState(false)

  return (
    <InputWithSuffix {...props} type={!isHidded ? 'password' : 'text'} suffixCss={passwordWithToggleSuffix}>
      <button className='toggle__button' onClick={() => setIsHideed(v => !v)}>
        {isHidded ? <EyeIcon className='toggle__icon' /> : <EyeSlashIcon className='toggle__icon' />}
      </button>
    </InputWithSuffix>
  )
}

export function Email (props) {
  return <Input {...props} type='email' />
}

/**
 * @typedef {{
 *  onInvalid?: function,
 *  onClick?: function,
 *  formatterFunction?: function,
 *  error?: boolean | string | number,
 *  customCss?: import('@emotion/core').SerializedStyles[],
 * }} MaskedInputExtendedProps
 *
 * @typedef {React.InputHTMLAttributes<HTMLInputElement> & MaskedInputExtendedProps} MaskedInputProps
 *
 * @type {React.ForwardRefExoticComponent<MaskedInputProps & React.RefAttributes<HTMLInputElement>>}
 */
export const MaskedInput = forwardRef(({
  value = '',
  onChange: emitChangeEvent = identity,
  onClick: emitClickEvent = identity,
  formatterFunction = identity,
  ...other
}, ref) => {
  const formattedValue = formatterFunction(value)
  const onChangeInput = value => emitChangeEvent(formatterFunction(value))
  return <Input ref={ref} value={formattedValue} onChange={onChangeInput} onClick={emitClickEvent} {...other} />
})

export const InputTimeMask = ({ value = '', onFocus, ...other }) => {
  const handleFocus = useCallback(event => {
    onFocus && onFocus(event)
    event.target.select()
  }, [onFocus])
  return (
    <MaskedInput maxLength='6' formatterFunction={maskTime} value={value} onFocus={handleFocus} {...other} />
  )
}

export const InputDurationMask = ({
  value = '',
  onChange: emitChangeEvent = identity,
  onBlur: emitBlurEvent = identity,
  ...other
}) => {
  const onBlur = event => {
    emitChangeEvent(treatDurationMinutes(value))
    emitBlurEvent(event)
  }
  return (
    <MaskedInput
      maxLength='6'
      formatterFunction={maskDuration}
      onChange={emitChangeEvent}
      onBlur={onBlur}
      value={value}
      {...other}
    />
  )
}

export const InputDurationMaskWithSuffix = ({
  value = '',
  onChange: emitChangeEvent = identity,
  onBlur: emitBlurEvent = identity,
  children,
  className,
  css = undefined,
  error,
  ...other
}) => {
  const onBlur = event => {
    emitChangeEvent(treatDurationMinutes(value))
    emitBlurEvent(event)
  }
  return (
    <div className={className} css={[inputContainer, css]}>
      <MaskedInputWithSuffix
        maxLength='6'
        formatterFunction={maskDuration}
        onChange={emitChangeEvent}
        onBlur={onBlur}
        value={value}
        css={removeVerticalSpacing('right')}
        error={error}
        {...other}
      >
        {children}
      </MaskedInputWithSuffix>
    </div>
  )
}

export const InputMoneyMask = ({ value = '', ...other }) => {
  const inputValue = useMemo(() => {
    if (typeof value === 'number') return parseFloat(value).toFixed(2)
    return value
  }, [value])

  return <MaskedInput formatterFunction={maskMoney} value={inputValue} {...other} />
}

export const InputCnpjMask = ({ value = '', onChange = identity, ...other }) => (
  <MaskedInput maxLength='19' formatterFunction={maskCnpj} onChange={onChange} value={value} {...other} />
)

export const InputCpfMask = ({ value = '', onChange = identity, ...other }) => (
  <MaskedInput maxLength='15' formatterFunction={maskCpf} onChange={onChange} value={value} {...other} />
)

export const InputPhoneMask = ({ value = '', onChange = identity, ...other }) => (
  <MaskedInput maxLength='17' formatterFunction={maskPhone} onChange={onChange} value={value} {...other} />
)

export const InputZipCodeMask = ({ value = '', onChange = identity, ...other }) => (
  <MaskedInput maxLength='9' formatterFunction={maskZipCode} onChange={onChange} value={value} {...other} />
)

export const InputHeightMask = ({ value = '', onChange = identity, ...other }) => (
  <MaskedInput maxLength='4' formatterFunction={maskHeight} onChange={onChange} value={value} {...other} />
)

export const InputWeightMask = ({ value = '', onChange = identity, ...other }) => (
  <MaskedInput maxLength='5' formatterFunction={maskWeight} onChange={onChange} value={value} {...other} />
)

export const InputExpirationDate = ({ value = '', onChange = identity, ...other }) => (
  <MaskedInput maxLength='8' formatterFunction={maskExpirationDate} onChange={onChange} value={value} {...other} />
)

export const InputCreditCard = ({ value = '', onChange = identity, ...other }) => (
  <MaskedInput maxLength='23' formatterFunction={maskCreditCard} onChange={onChange} value={value} {...other} />
)

export const InputDate = ({ value = '', ...other }) => (
  <MaskedInput maxLength='11' formatterFunction={maskDate} value={value} {...other} />
)

export const InputWithSuffix = forwardRef(({
  children,
  className,
  disabled: isDisabled,
  readOnly,
  error: hasError = false,
  css = undefined,
  inputCss = undefined,
  suffixCss = undefined,
  ...other
}, ref) => (
  <div className={className} css={[inputContainer, css]}>
    <Input {...other} error={hasError} disabled={isDisabled} readOnly={readOnly} css={[width100, removeVerticalSpacing('right'), inputCss]} ref={ref}/>
    <Suffix error={hasError} disabled={isDisabled || readOnly} css={suffixCss}>
      {children}
    </Suffix>
  </div>
))

export const InputWithPrefix = ({
  children,
  className,
  disabled: isDisabled,
  readOnly,
  error: hasError = false,
  css = undefined,
  ...other
}) => (
  <div className={className} css={[inputContainer, css]}>
    <Prefix error={hasError} disabled={isDisabled || readOnly}>
      {children}
    </Prefix>
    <Input {...other} error={hasError} disabled={isDisabled} readOnly={readOnly} css={[width100, removeVerticalSpacing('left')]} />
  </div>
)

export const MaskedInputWithPrefix = ({
  children,
  className,
  disabled: isDisabled,
  readOnly,
  error: hasError = false,
  css = undefined,
  ...other
}) => (
  <div className={className} css={[inputContainer, css]}>
    <Prefix error={hasError} disabled={isDisabled || readOnly}>
      {children}
    </Prefix>
    <MaskedInput {...other} error={hasError} readOnly={readOnly} disabled={isDisabled} css={[width100, removeVerticalSpacing('left')]} />
  </div>
)

export const MaskedInputWithSuffix = ({
  children,
  className,
  disabled: isDisabled,
  readOnly,
  error: hasError = false,
  css = undefined,
  ...other
}) => (
  <div className={className} css={[inputContainer, css]}>
    <MaskedInput
      {...other}
      error={hasError}
      readOnly={readOnly}
      disabled={isDisabled}
      css={[width100, removeVerticalSpacing('right')]}
    />
    <Suffix error={hasError} disabled={isDisabled || readOnly}>
      {children}
    </Suffix>
  </div>
)

export const EmailInputWithSuffix = ({
  children,
  className,
  disabled: isDisabled,
  readOnly,
  error: hasError = false,
  css = undefined,
  ...other
}) => (
  <div className={className} css={[inputContainer, css]}>
    <Input {...other} type='email' css={[width100, removeVerticalSpacing('right')]} error={hasError} />
    <Suffix error={hasError} disabled={isDisabled || readOnly}>
      {children}
    </Suffix>
  </div>
)

const empty = () => ''
const defaultAvailableDocuments = [
  {
    value: 'Cpf',
    label: 'CPF'
  },
  {
    value: 'Cnpj',
    label: 'CNPJ'
  }
]
const defaultDocumentMasks = {
  Cpf: maskCpf,
  Cnpj: maskCnpj,
  Passport: value => value.replace(/[^a-zA-Z0-9]/g, '').substring(0, 9)
}

export const DocumentInput = ({
  documentType = '',
  documentValue = '',
  onDocumentChange = identity,
  onDocumentBlur = identity,
  onDocumentValueChange = identity,
  onDocumentValueBlur = identity,
  availableDocuments = defaultAvailableDocuments,
  documentsMasks = defaultDocumentMasks,
  selectAriaLabel = '',
  ariaLabel = '',
  disabled = false,
  ...props
}) => {
  const formatterFunction = useMemo(
    () => documentType
      ? documentsMasks[documentType] || identity
      : empty,
    [documentType, documentsMasks]
  )
  const onChangeType = type => {
    onDocumentChange(type)
    onDocumentValueChange('')
  }

  return (
    <MaskedInputWithPrefix
      {...props}
      value={documentValue}
      onChange={onDocumentValueChange}
      onBlur={onDocumentValueBlur}
      formatterFunction={formatterFunction}
      ariaLabel={ariaLabel}
      disabled={disabled}
    >
      <Select
        css={documentSelect}
        options={availableDocuments}
        value={documentType}
        // eslint-disable-next-line
        onChange={useCallback(onChangeType)}
        onBlur={onDocumentBlur}
        ariaLabel={selectAriaLabel}
        disabled={disabled}
      />
    </MaskedInputWithPrefix>
  )
}

export const InputPercentage = ({
  onChange = identity,
  value = '',
  step = '0.5',
  min = '0',
  max = '100',
  ...props
}) => {
  return (
    <InputWithSuffix
      suffixCss={percentageInputSuffixStyle}
      inputCss={percentageInputStyle}
      type='number'
      step={step}
      min={min}
      max={max}
      value={value}
      onChange={onChange}
      {...props}
    >
      %
    </InputWithSuffix>
  )
}
