import { useState } from 'react'
import { useCompany } from 'Shared/contexts/Company'
import { IntegrationService } from 'Core/Service/Integration'
import { useHistory } from 'react-router'
import { useToast } from '@bonitour/components'
import { usePermissions } from 'Shared/hooks/usePermissions'
import { Integrations } from 'Shared/constants/integrations'

export const useCreateIntegration = () => {
  const [loading, setLoading] = useState(false)
  const { id: companyId } = useCompany()
  const { push } = useHistory()
  const { add: addToast } = useToast()
  const { handlePermission } = usePermissions()

  const createIntegration = async (integration) => {
    const integrationData = { companyId, ...integration }
    const isLimber = integrationData.integration === Integrations.Limber
    const Service = isLimber
      ? IntegrationService.limber
      : IntegrationService

    setLoading(true)
    try {
      const { integration } = await Service.create(integrationData).finally(() => setLoading(false))
      addToast('Vinculação realizada com sucesso', 'success')
      if (integration) {
        push(`/app/integrations/${integration?.id}/edit`)
      } else {
        push('/app/integrations')
      }
    } catch (error) {
      const errorData = error?.data?.extra_data
      if (isLimber && errorData?.status === 'unauthorized') {
        addToast('Credenciais inválidas')
        return
      }

      const hasPermission = handlePermission(error, 'Você não possui permissão de vinculação')
      hasPermission && addToast('Ocorreu um erro na vinculação')
    }
  }

  return {
    submit: createIntegration,
    loading
  }
}
