/** @jsxRuntime classic */
/** @jsx jsx */
import { Loading, useToast } from '@bonitour/components'
import { jsx } from '@emotion/core'
import { UserInviteService } from 'Core/Service/UserInvite/Service'
import { useEffect, useState } from 'react'
import { AffiliateInviteMessage } from './AffiliateInviteMessage'
import { AffiliateInviteForm } from './AffiliateInviteForm'
import { affiliateInviteContainer } from './AffiliateInvite.style'
import { useParams, useHistory } from 'react-router-dom'
import { AuthenticationProvider, useAuthentication } from 'Shared/contexts/Authentication'
import { UserProvider } from 'Shared/contexts/User'
import { CompanyProvider } from 'Shared/contexts/Company'
import { AuthorizationProvider } from 'Shared/contexts/Authorization'
import { RequireLogoutDialog } from 'Domains/Auth/Invites/RequireLogout'

const headings = [{ title: 'Obtendo informações do convite' }]

export const AffiliateInvite = () => {
  useAuthentication()
  const { invite: token } = useParams()

  const { add: addToast } = useToast()
  const [affiliateInvite, setAffiliateInvite] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const { push } = useHistory()

  useEffect(() => {
    if (affiliateInvite) {
      return
    }
    UserInviteService.get(token)
      .then(invite => {
        setAffiliateInvite(invite)
        if (invite.answeredAt) {
          addToast('Convite já confirmado')
          push('/auth/invite-error')
        }
      })
      .catch(() => {
        addToast('Ocorreu um erro ao buscar pelo convite')
        push('/auth/invite-error')
      })
      .finally(() => setIsLoading(false))
  }, [token, affiliateInvite, addToast, push])

  return (
    <UserProvider>
      <CompanyProvider>
        <AuthenticationProvider>
          <AuthorizationProvider requireLogin={false}>
            <div css={affiliateInviteContainer}>
              <div>
                <AffiliateInviteMessage companyName={affiliateInvite?.companyName} percentage={affiliateInvite?.affiliatePercentage} />
                <AffiliateInviteForm token={token} affiliate={affiliateInvite} />
                <RequireLogoutDialog requiredEmail={affiliateInvite?.userEmail} isLoading={isLoading}/>
              </div>
            </div>
            <Loading isLoading={isLoading} headings={headings} />
          </AuthorizationProvider>
        </AuthenticationProvider>
      </CompanyProvider>
    </UserProvider>
  )
}
