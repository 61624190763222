/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { Button, Card, flexColumnCentered, EmptyResult, LinkIcon, CircleThumbnail, flexRowCentered, PlusIcon, P, H2, HeaderPage } from '@bonitour/components'
import { jsx } from '@emotion/core'
import { useListIntegration } from '../hooks/useListIntegration'
import { ConfirmIntegration } from '../Modal/ConfirmIntegration'
import { useCreateIntegration } from '../hooks/useCreateIntegration'
import { useHistory } from 'react-router'
import { marginTop, marginBottom, clickable } from 'assets/styles/global'
import { IntegrationsList } from './IntegrationsList'
import { head } from '@bonitour/common-functions'
import { newIntegration, circleBackground, newIntegrationCircle, iconSize, newIntegrationText, subtitle, emptyResult } from './integrations.style'

const marginTop20 = marginTop(20)
const marginBottom50 = marginBottom(50)

export const Integrations = () => {
  const {
    changePagination,
    integrations,
    meta,
    loading,
    isConfirmIntegrationVisible,
    toggleConfirmIntegrationVisible,
    refetch
  } = useListIntegration()
  const { submit } = useCreateIntegration()
  const { push } = useHistory()

  const hasIntegrations = Boolean(head(integrations))

  const backToDashboard = () => push('/app')

  const onSubmit = async (integration) => {
    await submit(integration)
    refetch()
  }

  return (
    <>
      <ConfirmIntegration
        confirmationVisible={isConfirmIntegrationVisible}
        toggleConfirmIntegrationVisible={toggleConfirmIntegrationVisible}
        submit={onSubmit}
      />

      <HeaderPage onBack={backToDashboard} title='Integrações' />

      <div css={[newIntegration, clickable]} onClick={toggleConfirmIntegrationVisible}>
        <CircleThumbnail size={22} css={newIntegrationCircle}>
          <PlusIcon />
        </CircleThumbnail>
        <P css={newIntegrationText}>Nova integração</P>
      </div>

      <H2 css={subtitle}>Minhas integrações</H2>
      {!hasIntegrations && !loading &&
        <Card css={[flexColumnCentered, marginBottom50]}>
          <EmptyResult
            css={emptyResult}
            size={258}
            title='Nenhuma integração criada'
            subtitle='Você ainda não tem integrações com sistemas externos'
          >
            <CircleThumbnail css={[flexRowCentered, circleBackground]} size={258}>
              <LinkIcon css={iconSize} />
            </CircleThumbnail>
          </EmptyResult>
          <Button css={marginTop20} onClick={toggleConfirmIntegrationVisible}>Vincular</Button>
        </Card>}

      {hasIntegrations &&
        <IntegrationsList
          loading={loading}
          integrations={integrations}
          pagesQuantity={meta?.totalPages}
          total={meta?.total}
          onPagination={changePagination}
        />}
    </>
  )
}
