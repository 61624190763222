import { colors, BREAK_POINTS } from '@bonitour/components'

import { css } from '@emotion/core'

export const affiliateInviteMessageContainer = css`
  margin: auto 0;

  @media (max-width: ${BREAK_POINTS.smallTablet}) {
    padding-top: unset;

    H2 {
        margin-bottom: 10px;
    }

    .affiliate_pecentage__image {
        display: none;
    }
  }
`

export const descriptionContainer = css`
  margin-top: 20px;

  .company_name__label {
    color: ${colors.indigo1};
    font-weight: bold;
  }

  @media (max-width: ${BREAK_POINTS.tablet}) {
    margin-top: unset;
  }
`
