/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { Input, InputFormGroup } from '@bonitour/components'
import { jsx } from '@emotion/core'
import { marginBottom15, marginTop10, width100 } from '../IntegrationDialog.styles'

export const PortalTurisIntegrationForm = ({ errors, onInputChange, onInputBlur, form }) => {
  return (
    <>
      <InputFormGroup errorMessage={errors.cnpj} label='CNPJ' css={marginTop10}>
        <Input css={width100} value={form?.cnpj} onChange={onInputChange('cnpj')} onBlur={onInputBlur('cnpj')} />
      </InputFormGroup>
      <InputFormGroup customCss={[marginBottom15]} errorMessage={errors.token} label='Token'>
        <Input css={width100} value={form?.token} onChange={onInputChange('token')} onBlur={onInputBlur('token')} />
      </InputFormGroup>
    </>
  )
}
