import { css } from '@emotion/core'

export const authDialogCss = css`
  form {
    max-height: calc(100vh - 5rem);
    overflow-y: auto;
    margin-top: -1.5rem;
  }
`

export const authDialogCloseCss = css`
  z-index: 1;
`
