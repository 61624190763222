/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useToast } from '@bonitour/components'

import { Signup as SignupPage } from './Page'
import { useAuthentication } from 'Shared/contexts/Authentication'

export const Signup = ({ mail, onLogin }) => {
  const { add: addToast } = useToast()
  const { signup } = useAuthentication()

  const onSuccess = async data => {
    try {
      await signup(data)
    } catch (error) {
      if ((error?.response?.data || error?.data)?.errors?.errors_msg?.email) {
        return addToast('E-mail já cadastrado')
      }

      addToast('Erro inesperado')
    }
  }

  const onValidationError = () => addToast('Preencha o formulário corretamente')

  return <SignupPage onSuccess={onSuccess} onValidationError={onValidationError} mail={mail} onLogin={onLogin} />
}
