import { AxiosInterceptor } from '@/external/requester'
import {
  acquirerDomain,
  acquirerDomainV2,
  activityDomain,
  activityDomainV3,
  additionInformationDomain,
  alertDomain,
  bankAccountByPaymentMethodDomain,
  bankAccountDomain,
  batchDomain,
  paymentBatchesStatusTotalsDomain,
  binamikInvoiceDomain,
  cardBrandsDomain,
  cashierDomain,
  cashierHandlingDomain,
  categoryDomain,
  checkoutDomain,
  checkoutDomainV2,
  communiquesCategoriesDomain,
  communiquesNotificationsDomain,
  companyCommuniquesDomain,
  companyDomain,
  companyTermsDomain,
  employeesDomain,
  exceptionalDayDomain,
  externalProviderDomain,
  feeDomain,
  financialTransactionsDomain,
  integrationDomain,
  limberIntegrationDomain,
  invitationDomain,
  invoiceDomain,
  myDayDomain,
  myDayTotalizersDomain,
  notificationDomain,
  offlineExperienceDomain,
  offlineTicketDomain,
  paidTicketsReportDomain,
  partnershipAccountDomain,
  partnershipGroupDomain,
  passengerDomain,
  paymentBatchDomain,
  paymentBatchDomainV2,
  paymentProvidersDomain,
  pendingTicketsReportDomain,
  pickUpAndDropOffDomain,
  pickUpAndDropOffMoovDomain,
  planDomain,
  providerDomain,
  providerRefundDomain,
  refundedTicketsDomain,
  reservationDomain,
  reservationHistoryDomain,
  reservationHistoryDomainV2,
  reservationMapDomain,
  reservationPaymentDomain,
  reservationPaymentDomainV2,
  reservationRefundDomain,
  reservationRefundDomainV2,
  reservationRevenueDomain,
  reservationTicketsDomain,
  reservationsDomain,
  reservationTotalizersDomain,
  rolesDomain,
  rolesDomainV2,
  saleReportDomain,
  saleReportExportDomain,
  sellerDomain,
  serviceDomain,
  subordinateDomain,
  termDomain,
  ticketDomain,
  ticketDomainV2,
  ticketIntegrationDomain,
  transportDomain,
  vacancyDomain,
  vendorDomain,
  voucherReportDomain,
  voucherReportTotalsDomain,
  voucherTurisCancelDomain,
  voucherTurisDomain,
  voucherUnicoDomain,
  timeZoneDomain,
  combinedExperienceDomain,
  sellableCombinedExperienceDomain
} from '@/domains/private'
import {
  automationRulesCommonDomain,
  bankAccountBillingDomain,
  bankConciliationDomain,
  bankStatementsDomain,
  bankTransferDomain,
  conditionDomain,
  conditionsOperatorsDomain,
  countPlanDomain,
  creditCardConciliationDomain,
  digitalAccountMovementDomain,
  eventDomain,
  eventsProcessorDomain,
  expenseDomain,
  expenseRevenueDomain,
  financialScheduleDomain,
  fullFinancialScheduleDomain,
  gatewayPaymentsDomain,
  gatewayPaymentsImportDomain,
  paymentMethodDomain,
  revenueDomain,
  supplierBankAccountDomain,
  supplierDomain,
  billingConfigurationDomain,
  cashDepositDomain
} from '@/domains/private/Billing'
import {
  braspagAccessTokenDomain,
  companyConfigDomain,
  currentDigitalAccountDomain,
  digitalAccountDomain,
  paymentDomain,
  refundDomain
} from '@/domains/private/Pay'
import { logoutDomain } from '@/domains/public/user/logout'
import { authDomain } from './public'
import { affiliateDomain, affiliateInviteDomain } from './private/affiliate'
import { userInviteDomain } from './private/userInvite'

export function initialize (baseURL: string, interceptFunction?: (interceptor: AxiosInterceptor) => void) {
  return {
    acquirerDomain: acquirerDomain(baseURL, interceptFunction),
    acquirerDomainV2: acquirerDomainV2(baseURL, interceptFunction),
    activityDomain: activityDomain(baseURL, interceptFunction),
    activityDomainV3: activityDomainV3(baseURL, interceptFunction),
    additionInformationDomain: additionInformationDomain(baseURL, interceptFunction),
    affiliateDomain: affiliateDomain(baseURL, interceptFunction),
    affiliateInviteDomain: affiliateInviteDomain(baseURL, interceptFunction),
    alertDomain: alertDomain(baseURL, interceptFunction),
    authDomain: authDomain(baseURL, interceptFunction),
    automationRulesCommonDomain: automationRulesCommonDomain(baseURL, interceptFunction),
    bankAccountBillingDomain: bankAccountBillingDomain(baseURL, interceptFunction),
    bankAccountByPaymentMethodDomain: bankAccountByPaymentMethodDomain(baseURL, interceptFunction),
    bankAccountDomain: bankAccountDomain(baseURL, interceptFunction),
    bankConciliationDomain: bankConciliationDomain(baseURL, interceptFunction),
    bankStatementsDomain: bankStatementsDomain(baseURL, interceptFunction),
    bankTransferDomain: bankTransferDomain(baseURL, interceptFunction),
    batchDomain: batchDomain(baseURL, interceptFunction),
    paymentBatchesStatusTotalsDomain: paymentBatchesStatusTotalsDomain(baseURL, interceptFunction),
    binamikInvoiceDomain: binamikInvoiceDomain(baseURL, interceptFunction),
    braspagAccessTokenDomain: braspagAccessTokenDomain(baseURL, interceptFunction),
    cardBrandsDomain: cardBrandsDomain(baseURL, interceptFunction),
    cashierDomain: cashierDomain(baseURL, interceptFunction),
    cashierHandlingDomain: cashierHandlingDomain(baseURL, interceptFunction),
    categoryDomain: categoryDomain(baseURL, interceptFunction),
    checkoutDomain: checkoutDomain(baseURL, interceptFunction),
    checkoutDomainV2: checkoutDomainV2(baseURL, interceptFunction),
    combinedExperienceDomain: combinedExperienceDomain(baseURL, interceptFunction),
    sellableCombinedExperienceDomain: sellableCombinedExperienceDomain(baseURL, interceptFunction),
    communiquesCategoriesDomain: communiquesCategoriesDomain(baseURL, interceptFunction),
    communiquesNotificationsDomain: communiquesNotificationsDomain(baseURL, interceptFunction),
    companiesDomain: companyDomain(baseURL, interceptFunction),
    companyCommuniquesDomain: companyCommuniquesDomain(baseURL, interceptFunction),
    companyConfigDomain: companyConfigDomain(baseURL, interceptFunction),
    companyTermsDomain: companyTermsDomain(baseURL, interceptFunction),
    timeZoneDomain: timeZoneDomain(baseURL, interceptFunction),
    conditionDomain: conditionDomain(baseURL, interceptFunction),
    conditionsOperatorsDomain: conditionsOperatorsDomain(baseURL, interceptFunction),
    countPlanDomain: countPlanDomain(baseURL, interceptFunction),
    creditCardConciliationDomain: creditCardConciliationDomain(baseURL, interceptFunction),
    currentDigitalAccountDomain: currentDigitalAccountDomain(baseURL, interceptFunction),
    digitalAccountDomain: digitalAccountDomain(baseURL, interceptFunction),
    digitalAccountMovementDomain: digitalAccountMovementDomain(baseURL, interceptFunction),
    employeesDomain: employeesDomain(baseURL, interceptFunction),
    eventDomain: eventDomain(baseURL, interceptFunction),
    eventsProcessorDomain: eventsProcessorDomain(baseURL, interceptFunction),
    exceptionalDayDomain: exceptionalDayDomain(baseURL, interceptFunction),
    expenseDomain: expenseDomain(baseURL, interceptFunction),
    expenseRevenueDomain: expenseRevenueDomain(baseURL, interceptFunction),
    externalProviderDomain: externalProviderDomain(baseURL, interceptFunction),
    feeDomain: feeDomain(baseURL, interceptFunction),
    financialScheduleDomain: financialScheduleDomain(baseURL, interceptFunction),
    financialTransactionsDomain: financialTransactionsDomain(baseURL, interceptFunction),
    fullFinancialScheduleDomain: fullFinancialScheduleDomain(baseURL, interceptFunction),
    gatewayPaymentsDomain: gatewayPaymentsDomain(baseURL, interceptFunction),
    gatewayPaymentsImportDomain: gatewayPaymentsImportDomain(baseURL, interceptFunction),
    integrationDomain: integrationDomain(baseURL, interceptFunction),
    limberIntegrationDomain: limberIntegrationDomain(baseURL, interceptFunction),
    invitationDomain: invitationDomain(baseURL, interceptFunction),
    invoiceDomain: invoiceDomain(baseURL, interceptFunction),
    logoutDomain: logoutDomain(baseURL, interceptFunction),
    myDayDomain: myDayDomain(baseURL, interceptFunction),
    myDayTotalizersDomain: myDayTotalizersDomain(baseURL, interceptFunction),
    notificationDomain: notificationDomain(baseURL, interceptFunction),
    offlineExperienceDomain: offlineExperienceDomain(baseURL, interceptFunction),
    offlineTicketDomain: offlineTicketDomain(baseURL, interceptFunction),
    paidTicketsReportDomain: paidTicketsReportDomain(baseURL, interceptFunction),
    partnershipAccountDomain: partnershipAccountDomain(baseURL, interceptFunction),
    partnershipGroupDomain: partnershipGroupDomain(baseURL, interceptFunction),
    passengerDomain: passengerDomain(baseURL, interceptFunction),
    paymentBatchDomain: paymentBatchDomain(baseURL, interceptFunction),
    paymentBatchDomainV2: paymentBatchDomainV2(baseURL, interceptFunction),
    paymentDomain: paymentDomain(baseURL, interceptFunction),
    paymentMethodDomain: paymentMethodDomain(baseURL, interceptFunction),
    paymentProvidersDomain: paymentProvidersDomain(baseURL, interceptFunction),
    pendingTicketsReportDomain: pendingTicketsReportDomain(baseURL, interceptFunction),
    pickUpAndDropOffDomain: pickUpAndDropOffDomain(baseURL, interceptFunction),
    pickUpAndDropOffMoovDomain: pickUpAndDropOffMoovDomain(baseURL, interceptFunction),
    planDomain: planDomain(baseURL, interceptFunction),
    providerDomain: providerDomain(baseURL, interceptFunction),
    providerRefundDomain: providerRefundDomain(baseURL, interceptFunction),
    refundDomain: refundDomain(baseURL, interceptFunction),
    refundedTicketsDomain: refundedTicketsDomain(baseURL, interceptFunction),
    reservationDomain: reservationDomain(baseURL, interceptFunction),
    reservationHistoryDomain: reservationHistoryDomain(baseURL, interceptFunction),
    reservationHistoryDomainV2: reservationHistoryDomainV2(baseURL, interceptFunction),
    reservationMapDomain: reservationMapDomain(baseURL, interceptFunction),
    reservationPaymentDomain: reservationPaymentDomain(baseURL, interceptFunction),
    reservationPaymentDomainV2: reservationPaymentDomainV2(baseURL, interceptFunction),
    reservationRefundDomain: reservationRefundDomain(baseURL, interceptFunction),
    reservationRefundDomainV2: reservationRefundDomainV2(baseURL, interceptFunction),
    reservationRevenueDomain: reservationRevenueDomain(baseURL, interceptFunction),
    reservationTicketsDomain: reservationTicketsDomain(baseURL, interceptFunction),
    reservationTotalizersDomain: reservationTotalizersDomain(baseURL, interceptFunction),
    allyReservationsDomain: reservationsDomain(baseURL, interceptFunction),
    revenueDomain: revenueDomain(baseURL, interceptFunction),
    rolesDomain: rolesDomain(baseURL, interceptFunction),
    rolesDomainV2: rolesDomainV2(baseURL, interceptFunction),
    saleReportDomain: saleReportDomain(baseURL, interceptFunction),
    saleReportExportDomain: saleReportExportDomain(baseURL, interceptFunction),
    sellerDomain: sellerDomain(baseURL, interceptFunction),
    serviceDomain: serviceDomain(baseURL, interceptFunction),
    subordinateDomain: subordinateDomain(baseURL, interceptFunction),
    supplierBankAccountDomain: supplierBankAccountDomain(baseURL, interceptFunction),
    supplierDomain: supplierDomain(baseURL, interceptFunction),
    termDomain: termDomain(baseURL, interceptFunction),
    ticketDomain: ticketDomain(baseURL, interceptFunction),
    ticketDomainV2: ticketDomainV2(baseURL, interceptFunction),
    ticketIntegrationDomain: ticketIntegrationDomain(baseURL, interceptFunction),
    transportDomain: transportDomain(baseURL, interceptFunction),
    userInviteDomain: userInviteDomain(baseURL, interceptFunction),
    vacancyDomain: vacancyDomain(baseURL, interceptFunction),
    vendorDomain: vendorDomain(baseURL, interceptFunction),
    voucherReportDomain: voucherReportDomain(baseURL, interceptFunction),
    voucherReportTotalsDomain: voucherReportTotalsDomain(baseURL, interceptFunction),
    voucherTurisCancelDomain: voucherTurisCancelDomain(baseURL, interceptFunction),
    voucherTurisDomain: voucherTurisDomain(baseURL, interceptFunction),
    voucherUnicoDomain: voucherUnicoDomain(baseURL, interceptFunction),
    billingConfigurationDomain: billingConfigurationDomain(baseURL, interceptFunction),
    cashDepositDomain: cashDepositDomain(baseURL, interceptFunction)
  }
}
