/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx, css } from '@emotion/core'
import { useCallback, useMemo, useRef, useState } from 'react'
import { identity } from '@bonitour/common-functions'
import { CheckboxInputGroup } from '../Checkbox/Checkbox'
import PropTypes from 'prop-types'
import { useClickOutside } from '@bonitour/app-functions'
import { Popover, Manager, Reference } from '../Popover/Popover'
import { InputFormGroup } from '../Input/InputFormGroup'
import { Input } from '../Input/Input'
import { colors } from '../../assets/styles/colors'
import { hidden } from '../../assets/styles/global'
import { iconHex } from '../../assets/styles/icons'
import { normalizeString } from '../../utils/string/normalizeString'

export const MultiSelect = ({
  options = [],
  isChecked = identity,
  allSelected = false,
  onOptionChange,
  onAllOptionsChange,
  isDisableSelectAll = false,
  ...others
}) => {
  const onAllScheduleChange = () => onAllOptionsChange()
  const uniqueId = useMemo(() => (Math.random() + 1).toString(36).substring(7), [])

  return (
    <div {...others}>
      {isDisableSelectAll
        ? null
        : (
          <CheckboxInputGroup id={`${uniqueId}-all`} checked={allSelected} onChange={onAllScheduleChange}>Selecionar todos</CheckboxInputGroup>
        )}

      {options.map(option => {
        const value = option?.value || option
        const label = option?.label || option
        return (
          <div key={value}>
            <CheckboxInputGroup
              id={`${uniqueId}-${value}`}
              onChange={onOptionChange(value)}
              checked={isChecked(value)}
            >
              {label}
            </CheckboxInputGroup>
          </div>
        )
      })}
    </div>
  )
}

MultiSelect.propTypes = {
  options: PropTypes.array.isRequired,
  isChecked: PropTypes.bool.isRequired,
  allSelected: PropTypes.bool.isRequired,
  onOptionChange: PropTypes.func.isRequired,
  onAllOptionsChange: PropTypes.func.isRequired
}

const inputCss = css`
  background-color: ${colors.white} !important;
  &.has-selected-label::placeholder {
    color: ${colors.gray4};
  }
`

const inputFormGroupCss = css`
  position: relative;
  &::after {
    font-family: 'bonifont' !important;
    content: '\\${iconHex('angle-down')}';
    color: ${colors.gray2};
    position: absolute;
    right: 22px;
    font-size: 13px;
    bottom: 14px;
    transition: 0.2s transform ease;
  }

  &.open::after {
    transform: rotate(-180deg);
  }

  input {
    padding-right: 40px;
  }
  &.disabled {
    input {
      background-color: ${colors.gray11} !important;
    }
  }
`

const popoverCss = css`
  > div {
    max-height: min(300px, calc(100vh - 130px));
    overflow-y: auto;

    label {
      max-width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 3px 0;
    }
  }
`

const wrapper = css`
  width: 100%;
`

export const MultiSelectDropdown = ({
  errorMessage,
  selected: selectedNullable,
  onChange,
  options: optionsNullable,
  customCss = [],
  label,
  placeholder,
  disabled = false,
  isDisableSelectAll = false,
  ...others
}) => {
  const [filter, setFilter] = useState()

  const reference = useRef()

  const [isListOpen, setListOpen] = useClickOutside(reference)

  const {
    selected,
    options
  } = useMemo(() => ({
    selected: selectedNullable || [],
    options: optionsNullable || []
  }), [selectedNullable, optionsNullable])

  const optionsMap = useMemo(() => {
    const map = new Map()

    options.forEach(option => {
      if (option.value) {
        map.set(option?.value, option.label)
      }
    })

    return map
  }, [options])

  const {
    areAllSelected,
    filteredOptions,
    selectedLabel = ''
  } = useMemo(() => ({
    areAllSelected: options.length === selected.length,
    filteredOptions: options.filter(option =>
      normalizeString(filter)
        .trim()
        .split(' ')
        .every(word => normalizeString(option?.label).includes(word))),
    selectedLabel: selected.map(value => optionsMap.get(value)).filter(Boolean).join(', ')
  }), [filter, options, optionsMap, selected])

  const hasSelectedLabel = useMemo(() => Boolean(selectedLabel), [selectedLabel])

  const inputFormGroupClassName = useMemo(() => [disabled && 'disabled', isListOpen && 'open'].join(' '), [disabled, isListOpen])

  const handleListOpen = useCallback(() => {
    if (!disabled) {
      setListOpen(!isListOpen)
    }
  }, [disabled, isListOpen, setListOpen])

  const onChangeAll = useCallback(() => {
    if (filter) {
      setFilter('')
    }
    areAllSelected
      ? onChange([])
      : onChange(options.map(option => option?.value ?? option))
  }, [areAllSelected, filter, onChange, options])

  const isSelected = useCallback((value) => selected.includes(value), [selected])

  const onChangeItem = useCallback((value, ...b) => () => {
    if (filter) {
      setFilter('')
    }
    if (isSelected(value)) {
      onChange(selected?.filter((item) => item !== value) || [])
    } else {
      onChange([...selected, value])
    }
  }, [filter, isSelected, onChange, selected])

  const handleFilterChange = useCallback(
    (value) => {
      setFilter(value)
    },
    [setFilter]
  )

  return (
    <div ref={reference} css={[wrapper, ...customCss]} {...others}>
      <Manager>
        <>
          <Reference>
            {({ ref }) => (
              <div ref={ref} onClick={handleListOpen}>
                <InputFormGroup errorMessage={errorMessage} label={label} customCss={[inputFormGroupCss]} className={inputFormGroupClassName}>
                  <Input
                    css={inputCss}
                    className={hasSelectedLabel && 'has-selected-label'}
                    placeholder={selectedLabel || placeholder}
                    value={filter}
                    onChange={handleFilterChange}
                    disabled={disabled}
                  />
                </InputFormGroup>
              </div>
            )}
          </Reference>
          <Popover css={[popoverCss, !isListOpen && hidden]}>
            <MultiSelect
              options={filteredOptions}
              isChecked={isSelected}
              onOptionChange={onChangeItem}
              onAllOptionsChange={onChangeAll}
              allSelected={areAllSelected}
              isDisableSelectAll={isDisableSelectAll}
            />
          </Popover>
        </>
      </Manager>
    </div>
  )
}
