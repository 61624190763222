/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useCallback, useMemo } from 'react'
import { InputFormGroup, Input, P, Button } from '@bonitour/components'
import { identity } from '@bonitour/common-functions'
import { inputMargin } from 'Domains/Signup/Form/Form.style'
import { useUser } from 'Shared/contexts/User'
import { InviteSignupLogin } from '../../InviteSignupLogin'

export const UserInviteForm = ({
  email,
  onAccept = identity,
  isLoading = false
}) => {
  const { authKey, user } = useUser()
  const hasUser = useMemo(() => Boolean(authKey), [authKey])

  const handleSubmit = useCallback(() => {
    onAccept({
      email: user?.email,
      name: user?.name,
      password: hasUser && '*'
    })
  }, [onAccept, user, hasUser])

  return (
    <>
      <InputFormGroup css={inputMargin} label='Email'>
        <Input name='email' value={email} disabled />
      </InputFormGroup>
      {hasUser
        ? (
          <>
            <InputFormGroup css={inputMargin} label='Nome Completo'>
              <Input name='name' value={user?.name} disabled required />
            </InputFormGroup>
            <P>Ao aceitar o convite, um novo perfil de funcionario da empresa será adicionado à sua conta.</P>
            <Button onClick={handleSubmit} className='submit__button' disabled={isLoading} >Aceitar convite</Button>
          </>
        )
        : (
          <>
            <P>Para aceitar o convite e acessar o painel da empresa, é necessário cadastrar ou entrar em uma conta com este email.</P>
            <InviteSignupLogin mail={email}/>
          </>
        )}
    </>
  )
}
