/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { TooltipOnHover } from '../Tooltip/Tooltip'
import { InfoIcon } from '../Icons/Icons'
import { amountInfoTooltipCss } from './InfoTooltip.style'

export const InfoTooltip = ({ text = "I'm am an example", size = 200 }) => {
  return (
    <TooltipOnHover css={amountInfoTooltipCss} text={text} size={size}>
      <span><InfoIcon/></span>
    </TooltipOnHover>)
}
