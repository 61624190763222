/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { Input, InputFormGroup, flexRowCentered } from '@bonitour/components'
import { jsx } from '@emotion/core'
import { inputLogin, marginBottom15, marginTop10, width100 } from '../IntegrationDialog.styles'

export const VoucherUnicoIntegrationForm = ({ errors, onInputChange, onInputBlur, form }) => {
  return (
    <>
      <InputFormGroup errorMessage={errors.companyName} label='Empresa' css={marginTop10}>
        <Input css={width100} value={form?.companyName} onChange={onInputChange('companyName')} onBlur={onInputBlur('companyName')} />
      </InputFormGroup>
      <div css={flexRowCentered}>
        <InputFormGroup errorMessage={errors.login} label={'Login'} css={[marginBottom15, inputLogin]}>
          <Input css={width100} value={form?.login} onChange={onInputChange('login')} onBlur={onInputBlur('login')} />
        </InputFormGroup>
        <InputFormGroup css={marginBottom15} errorMessage={errors.password} label={'Senha'}>
          <Input type='password' css={width100} value={form?.password} onChange={onInputChange('password')} onBlur={onInputBlur('password')} />
        </InputFormGroup>
      </div>
    </>
  )
}
