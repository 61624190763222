/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { colors } from '../../assets/styles/colors'
import { title1 } from '../../assets/styles/fonts'
import { LoadingAnimation } from './LoadingAnimation'
import { useEffect, useRef, useState } from 'react'
import { Backdrop } from '../Backdrop/Backdrop'

const loadingCard = css`
  width: 50vw;
  height: 50vh;
  margin: auto;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;

  border-radius: 10px;
  background: ${colors.white};
  box-shadow: 0px 0px 6px #0000001a;
  color: ${colors.gray3};
  ${title1};

  @media screen and (max-width: 768px){
    width: 90vw;
    height: 80vh;
  }
`

const containedLoadingCard = css`
  width: 100% !important;
  height: 100% !important;
  border-radius: 10px;
`

export const LoadingContainer = ({ backgroundStyle, contained = false, ...props }) => (
  <>
    <Backdrop css={[backgroundStyle, contained && containedLoadingCard]} />
    <div {...props} css={[loadingCard, contained && containedLoadingCard]} />
  </>
)

const loadingText = css`
  max-width: 750px;
  margin: 10px auto;
`

const centerAnimation = css`
 margin: 20px auto;
`

export const Loading = ({ isLoading = false, headings = [], backgroundStyle = '', contained = false }) => {
  const [text, setText] = useState('')
  const headingTimeouts = useRef([])

  useEffect(() => {
    if (isLoading) {
      headingTimeouts.current = headings.map(({ title = '', timeout = 0 }) => {
        return setTimeout(() => {
          setText(title)
        }, timeout)
      })
    }
    return (
      () => {
        if (headingTimeouts.current) {
          headingTimeouts.current.forEach(clearTimeout)
          headingTimeouts.current = []
        }
      }
    )
  }, [isLoading, headings])

  if (!isLoading) { return null }
  return (
    <LoadingContainer backgroundStyle={backgroundStyle} contained={contained}>
      <div>
        <div css={loadingText}>
          {text}
        </div>
        <LoadingAnimation css={centerAnimation} />
      </div>
    </LoadingContainer>
  )
}
