/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Loading, useToast } from '@bonitour/components'
import { ForgotPasswordPage } from './Page'
import { AuthService } from 'Core/Service/Auth'
import { useState } from 'react'

const headings = [{
  title: 'Enviando solicitação'
}]

export const ForgotPassword = ({ mail, onLogin }) => {
  const { add: addToast } = useToast()
  const [isLoading, setLoadingStatus] = useState(false)

  const onSuccess = async data => {
    setLoadingStatus(true)
    try {
      await AuthService.forgotPassword(data)
      addToast('Solicitação enviada com sucesso para o seu e-mail', 'success')
    } catch (error) {
      const errorMessage = error?.data?.errors_msg
      const errorCode = error?.data?.errors

      const errorMessages = {
        'authentication::user::not_found': ['Solicitação enviada com sucesso para o seu e-mail', 'success'],
        default: [`Ocorreu um erro na solicitação de alteração de senha. ${errorCode}`]
      }
      addToast(...(errorMessages[errorMessage] ?? errorMessages.default))
    }
    setLoadingStatus(false)
  }

  const onValidationError = () => addToast('Digite o e-mail cadastrado no sistema')

  return (
    <>
      <ForgotPasswordPage
        onSuccess={onSuccess}
        onValidationError={onValidationError}
        onLogin={onLogin}
        mail={mail}
      />
      <Loading isLoading={isLoading} headings={headings} contained={Boolean(mail)} />
    </>
  )
}
