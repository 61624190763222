import { BREAK_POINTS, colors } from '@bonitour/components'
import { css } from '@emotion/core'

export const formContainer = css`
  .inputs__container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .buttons__container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    @media (max-width: ${BREAK_POINTS.phone}) {
      flex-direction: column;
    }
  }

  .submit__button {
    width: 100%;
  }


  p.description___label {
    font-size: 21px;
  }

  h3 > span {
    color: ${colors.primary}
  }

  @media (max-width: ${BREAK_POINTS.tablet}) {
    padding-top: 20px;
    min-width: unset;
  }

  p {
    line-height: 1.1;
  }
`
