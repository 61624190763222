/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Button, flexCenteredSpaceBetweenRow, H2, P } from '@bonitour/components'
import { useForm } from '@bonitour/app-functions'
import { identity } from '@bonitour/common-functions'
import { useHistory } from 'react-router-dom'
import { ForgotPasswordForm } from './Form/Form'
import { forgotPasswordFormSchema } from './Form/Form.schema'
import { returnLink } from 'Domains/AuthLayout/AuthLayout.style'
import { useCallback, useEffect } from 'react'

const defaultFormState = {}

const preventFormDefault = event => event.preventDefault()

export const ForgotPasswordPage = ({
  onSuccess: emitSuccessEvent = identity,
  onValidationError: emitValidationError = identity,
  onLogin,
  mail
}) => {
  const {
    form,
    errors,
    onSubmit,
    utils: { onInputChange, onInputBlur }
  } = useForm(defaultFormState, forgotPasswordFormSchema)

  const onClick = onSubmit(emitSuccessEvent, emitValidationError)

  useEffect(() => {
    if (mail && form.email !== mail) {
      onInputChange('email')(mail)
    }
  }, [mail, form.email, onInputChange])

  const { push } = useHistory()

  const handleLogin = useCallback(() => {
    if (onLogin) {
      onLogin()
    } else {
      push('/')
    }
  }, [onLogin, push])

  return (
    <form onSubmit={preventFormDefault}>
      <H2>Esqueci minha senha</H2>
      <P>Digite seu e-mail de cadastro. Nós lhe enviaremos um e-mail com link para recadastrar sua senha caso sua conta já exista.</P>
      <ForgotPasswordForm
        forgotPassword={form}
        forgotPasswordErrors={errors}
        onChange={onInputChange}
        onBlur={onInputBlur}
        onSubmit={onClick}
        forceEmail={Boolean(mail)}
      />
      <div css={flexCenteredSpaceBetweenRow}>
        <button type='submit' onClick={handleLogin} css={returnLink}>Realizar Login</button>
        <Button type='submit' onClick={onClick}>Enviar</Button>
      </div>
    </form>
  )
}
