import { authenticatedService } from '@/interfaces/authenticatedService'
import { extractInnerData, extractData } from '@/utils/data-extractor'
import { decodeErrors as errorTranslate } from '@/utils/error-decoder'
import { formatDate } from '@bonitour/common-functions'
import { FeeType, FeeError, ConflictObject } from '@/types/domains/Fee'

function getConflictingPeriods (conflicts: Array<ConflictObject> | Record<string, any>) {
  if (Array.isArray(conflicts)) {
    return conflicts
  } else {
    return Object.entries(conflicts).map(period => period[1].conflicts[0])
  }
}

function decodeError (error: FeeError) {
  if (/conflited_date_range/.test(error.errors_msg)) {
    const conflictingPeriods = getConflictingPeriods(error.extra_data.conflicting_periods)
      .map(({ label, initial_date, final_date }) =>
        `${label} (${formatDate(initial_date)} - ${formatDate(final_date)})`)
      .reduce((acc, curr) => `${acc}, ${curr}`)

    return `Período conflitante com: ${conflictingPeriods}`
  }
  return 'Ocorreu um erro ao criar/atualizar a tarifa'
}

const feeService = authenticatedService('/v1/fees')

const decodeErrors = (errors: Array<string>) => errorTranslate(errors, decodeError)

function get (serviceId: string) {
  const paramObject = { service_id: serviceId }

  return feeService.get(paramObject).then(extractInnerData)
}

function getById (feeId: string, serviceId: string) {
  const paramObject = { service_id: serviceId }

  return feeService.get(paramObject, feeId).then(extractInnerData)
}

function remove (serviceId: string, feeId: string) {
  const paramObject = { service_id: serviceId }

  return feeService.remove(paramObject, feeId)
}

function getPrices (companyId: string, serviceId: string, date: string, hour: string, type: string, pickupPlaceId: string) {
  const paramObject = {
    service_id: serviceId,
    company_id: companyId,
    date,
    hour,
    type,
    pickup_place_id: pickupPlaceId
  }
  return feeService.get(paramObject, 'pax_prices').then(extractInnerData)
}

function create (fee: FeeType) {
  return feeService.post(fee)
    .then(extractData)
    .catch(({ data }: { data: any }) => decodeErrors(data))
}

function update (feeId: string, fee: FeeType) {
  return feeService.patch(fee, feeId)
    .then(extractData)
    .catch(({ data }: { data: any }) => decodeErrors(data))
}

feeService.setServiceMethods({ get, getById, getPrices, remove, create, update })

export const feeDomain = feeService.initialize
