/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import { Portal } from '../Portal/Portal'
import { container, paleButton, content, buttonsContainer } from './CookieConsent.style'
import { Button } from '../Buttons/Buttons'
import { useState } from 'react'

export const CookieConsent = ({
  message,
  mode = 'simplified',
  isVisible = false,
  acceptButtonLabel = 'Ok, entendi!',
  detailButtonLabel = 'Detalhes',
  rejectButtonLabel = 'Recusar',
  onAccept,
  onReject,
  children,
  ...other
}) => {
  const [cookieConsentModes, setCookieConsentModes] = useState(mode)
  const onDetailClick = () => setCookieConsentModes('detailed')

  return (
    <Portal>
      {isVisible
        ? <div css={container} {...other}>
          {cookieConsentModes === 'simplified' &&
            <div css={content}>
              <p>{message}</p>
              <div css={buttonsContainer}>
                <button css={paleButton} onClick={onDetailClick}>{detailButtonLabel}</button>
                <Button onClick={onAccept}>{acceptButtonLabel}</Button>
              </div>
            </div>}

          {cookieConsentModes === 'detailed' &&
            <div css={content}>
              {children}
              <div css={buttonsContainer}>
                <button css={paleButton} onClick={onReject}>{rejectButtonLabel}</button>
                <Button onClick={onAccept}>{acceptButtonLabel}</Button>
              </div>
            </div>}
        </div>
        : null}
    </Portal>
  )
}

CookieConsent.propTypes = {
  message: PropTypes.string,
  mode: PropTypes.string,
  acceptButtonLabel: PropTypes.string,
  detailButtonLabel: PropTypes.string,
  rejectButtonLabel: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
  onReject: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired
}
