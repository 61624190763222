import { useDebounce } from '@bonitour/app-functions'
import { useMemo, useState, useCallback } from 'react'

export const useSelect = (options = []) => {
  const [selectInput, setSelectInput] = useState('')
  const [availableOptions, setAvailableOptions] = useState(0)
  const debouncedValue = useDebounce(selectInput, 300)

  const filteredOptions = useMemo(() => {
    if (!(debouncedValue && debouncedValue.replace(/\s/g, '').length !== 0)) {
      setAvailableOptions(options.length)
      return options
    }

    let availableCounter = 0
    const valueLowercase = (debouncedValue && debouncedValue.toLowerCase()) || ''

    const builtOptions = options.map(item => {
      const labelLowercase = Boolean(item?.label) && item.label.toLowerCase()

      const isVisible = labelLowercase && labelLowercase.indexOf(valueLowercase) !== -1

      if (isVisible) {
        availableCounter += 1
      }
      return ({ ...item, isVisible })
    })
    setAvailableOptions(availableCounter)

    const builtFilteredOptions = builtOptions.filter(({ label }) => label && label.toLowerCase().includes(valueLowercase))

    return builtFilteredOptions
  }, [options, debouncedValue])

  return {
    selectInput,
    availableOptions,
    setSelectInput,
    filteredOptions
  }
}

export const useSelectPosition = (options) => {
  const [focusedIndex, setFocusedIndex] = useState()

  const getStep = useCallback((stepSize, stepLocation) => {
    const optionsSize = options.length - 1
    if (stepLocation === undefined) {
      return 0
    }
    if (stepSize === 0) {
      return stepLocation
    }

    let desiredPosition = stepLocation + stepSize

    if (desiredPosition > optionsSize) {
      desiredPosition = 0
    } else if (desiredPosition < 0) {
      desiredPosition = optionsSize
    }

    const { isVisible = true } = options[desiredPosition]

    if (isVisible) {
      return desiredPosition
    }

    return getStep(stepSize, desiredPosition)
  }, [options])

  const step = useCallback(stepSize => {
    setFocusedIndex(previousIndex => getStep(stepSize, previousIndex))
  }, [getStep])

  return {
    focusedIndex,
    setFocusedIndex,
    step
  }
}
