/** @jsxRuntime classic */
/** @jsx jsx */
import { Button, Dialog, GhostButton, HomeIcon, LogoutIcon } from '@bonitour/components'
import { jsx } from '@emotion/core'
import { useCallback, useMemo } from 'react'
import { closeDialogCss, dialogCss } from './RequireLogout.style'
import { useUser } from 'Shared/contexts/User'
import { ShortcutItem } from 'Domains/Layout/Shortcuts'
import { UserStore } from 'Core/Store/User'

export const RequireLogoutDialog = ({ requiredEmail, isLoading = false }) => {
  const { user, authKey } = useUser()
  const hasUser = useMemo(() => Boolean(authKey), [authKey])
  const goHome = useCallback(() => { window.location = '/' }, [])
  const loggedEmail = useMemo(
    () => user?.email,
    [user]
  )
  const logoutNeeded = useMemo(
    () => !isLoading && hasUser && (loggedEmail !== requiredEmail),
    [loggedEmail, requiredEmail, isLoading, hasUser]
  )
  const handleLogout = useCallback(() => {
    localStorage.clear()
    UserStore.clear()
    setTimeout(() => {
      window.location.reload()
    }, 500)
  }, [])

  return (
    <Dialog isVisible={logoutNeeded} title='Logout necessário!' customContainercss={dialogCss} customButtonCss={[closeDialogCss]}>
      <p>É necessário sair da conta atual para visualizar este convite.</p>
      {requiredEmail
        ? (
          <>
            <p>Este convite é válido somente para o email: <pre>{requiredEmail}</pre></p>
            {user?.email && <p>Logado com o email: <pre>{user?.email}</pre></p>}
          </>
        )
        : null}
      <Button type="button" onClick={handleLogout} className='primary'>
        <ShortcutItem icon={LogoutIcon} label='Sair' />
      </Button>
      <GhostButton type="button" onClick={goHome}>
        <ShortcutItem icon={HomeIcon} label='Voltar ao início' />
      </GhostButton>
    </Dialog>
  )
}
