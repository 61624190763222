import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo
} from 'react'

import { TableHeader } from './Header'
import { MobileSortMenu } from './MobileSortMenu'

import { buildColumnTemplate, validateProps } from './helpers'

import { TableBody, TableHeaderRow, TableWrapper } from './styles'
import { Loading } from './Loading'
import { EmptyResult } from './EmptyResult'
import { defaultSortingHandler, TableProvider } from './TableContext'

/**
 * @typedef {Object} SortingProps
 * @property {string} column
 * @property {SortOrder} order
 */

/**
 * @typedef {import('@emotion/react').SerializedStyles} SerializedStyles
 */

/**
 * @typedef TableProps
 * @property {string[]} columnsWidths
 * @property {string[]} labels
 * @property {number} [mobileBreakpoint]
 * @property {SerializedStyles | SerializedStyles[]} [customCss]
 * @property {(arg0: SortingProps) => void} [onSortingChange]
 * @property {boolean} [isLoading]
 * @property {string[]} [sortableColumns]
 * @property {string} [emptyMessage]
 * @property {number} [sortedColumn]
 * @property {'asc' | 'desc'} [sortedOrder]
 */

/** @type { React.FC<TableProps> } */
const TableV2 = ({
  labels,
  children,
  columnsWidths,
  customCss,
  onSortingChange = defaultSortingHandler,
  mobileBreakpoint = 900,
  sortableColumns = [],
  isLoading = false,
  emptyMessage = 'Nenhum item encontrado',
  sortedColumn,
  sortedOrder = ''
}) => {
  const columnTemplate = useMemo(
    () => buildColumnTemplate(labels.length, columnsWidths),
    [columnsWidths, labels]
  )

  const handleSortChange = useCallback(
    column => order => {
      const columnName = labels[column]

      if (sortableColumns.includes(columnName)) {
        onSortingChange({ column, order })
      }
    },
    [onSortingChange, labels, sortableColumns]
  )

  useEffect(() => {
    validateProps({
      labels,
      children,
      columnsWidths,
      mobileBreakpoint,
      sortableColumns
    })
  }, [
    children,
    columnsWidths,
    labels,
    mobileBreakpoint,
    sortableColumns
  ])

  const isEmpty = useMemo(() => !children.length, [children])

  return (
    <TableProvider
      labels={labels}
      columnsWidths={columnsWidths}
      sortedColumn={sortedColumn}
      sortedOrder={sortedOrder}
      mobileBreakpoint={mobileBreakpoint}
      sortableColumns={sortableColumns}
    >
      <TableWrapper
        columnTemplate={columnTemplate}
        css={customCss}
        mobileBreakpoint={mobileBreakpoint}
        layout
      >
        <MobileSortMenu onSortingChange={onSortingChange} />
        <TableHeaderRow layout>
          {labels.map((label, index) => (
            <TableHeader
              key={index}
              label={label}
              columnIdx={index}
              onChange={handleSortChange(index)}
            />
          ))}
        </TableHeaderRow>
        <TableBody>
          {!isLoading
            ? (
              isEmpty
                ? (
                  <EmptyResult message={emptyMessage} />
                )
                : (
                  children.map((child, idx) => (
                    <Fragment key={idx}>{child}</Fragment>
                  ))
                )
            )
            : (
              <Loading />
            )}
        </TableBody>
      </TableWrapper>
    </TableProvider>
  )
}

export { TableV2 }
