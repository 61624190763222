import { authenticatedService } from '@/interfaces/authenticatedService'
import { Integration, IntegrationLink, LimberService } from '@/types/domains/Integration'
import { extractData } from '@/utils/data-extractor'
import { cleanPayload } from '@/utils/clean-payload'
import { MetaOnRequest } from '@/types/domains/Abstractions'

const integrationService = authenticatedService('v1/integrations')
const limberIntegrationService = authenticatedService('v1/limber/integrations')

const create = (Integration: Integration): Promise<any> => {
  return integrationService.post(Integration).then(extractData)
}

const list = (searchParams: Record<string, any>, pagination: MetaOnRequest): Promise<any> => {
  const params = { ...searchParams }

  return integrationService.get({
    ...cleanPayload(params),
    ...pagination
  }).then(extractData)
}

const get = (integrationId: string): Promise<Integration | any> => integrationService.get({}, integrationId).then(extractData)

const link = (integrationId: string, integrationLink: IntegrationLink) => {
  return integrationService.post(integrationLink, `${integrationId}/integration_services`).then(extractData)
}

const remove = (integrationId: string, integrationActivityId: string): Promise<any> => integrationService.remove({}, `${integrationId}/integration_services/${integrationActivityId}`)

const update = (integrationId: string, integration: Integration): Promise<any> => {
  return integrationService.patch(cleanPayload(integration), integrationId)
}

const createLimberIntegration = (Integration: Integration): Promise<any> => {
  return limberIntegrationService.post(Integration).then(extractData)
}

const importLimberService = (integrationId: string, ServiceData: any): Promise<any> => {
  return limberIntegrationService.post(ServiceData, `${integrationId}/integration_services`).then(extractData)
}

const listLimberServices = (IntegrationId: string): Promise<LimberService> => {
  return limberIntegrationService.get({}, `${IntegrationId}/skus`).then(extractData)
}

integrationService.setServiceMethods({
  create,
  list,
  get,
  link,
  remove,
  update
})
limberIntegrationService.setServiceMethods({
  create: createLimberIntegration,
  import: importLimberService,
  list: listLimberServices
})

export const integrationDomain = integrationService.initialize
export const limberIntegrationDomain = limberIntegrationService.initialize
